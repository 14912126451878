import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return <>
    <section className="footer">
      <Container>
        <div className="footer-top">
          <img src={require("../assets/images/logo.png")} alt="img" />
          <ul>
            <li>About Us</li>
            <li>FAQ</li>
            <li>Contact</li>
            <li>Terms and Conditions</li>
            <li>Privacy Policy</li>
          </ul>
        </div>
 </Container>
  </section>
    <section className="footer-bottom">
      <Container>
        <Row>
          <Col md={9} lg={9}>
            <div className="footer-bottom-left">
              <p>Copyright Information: Copyright © [2024] Defi Betting. All rights reserved.</p>
            </div>
          </Col>
          <Col md={3} lg={3}>
            <div className="footer-bottom-right">
              <ul>
                <li> <i class="fa fa-facebook-square" aria-hidden="true"></i> </li>
                <li><i class="fa fa-instagram" aria-hidden="true"></i></li>
                <li><i class="fa fa-linkedin-square" aria-hidden="true"></i></li>
              </ul>
          </div>
          </Col>
        </Row>
      </Container>
  </section>
  </>;
  };
  export default Footer;
  
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Web3 from 'web3';
import { contractDetails } from '../config/config';
export const CustomMarks = ({ value, setValue }) => {
    const marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 2,
            label: '2',
        },
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 5,
            label: '5',
        },
    ];

    function valuetext(value) {
        return `${value}`;
    }

    return (<>
        <Box>
            <Slider
                aria-label="Custom marks"
                defaultValue={value}
                getAriaValueText={valuetext}
                step={1}
                min={1}
                max={5}
                valueLabelDisplay="auto"
                marks={marks}
                value={value}
                onChange={(event) => setValue(event.target.value)}
            />
        </Box>
    </>)

}

export const CreateNewContract = async (contractType, chainName, tokenName) => {
    const web3 = new Web3(window.ethereum);
    let contractMethods;
    if (contractType == "SPIN_WHEEL") {
        console.log("========",contractType)
        console.log("========",contractDetails)
        try{
            contractMethods = new web3.eth.Contract(contractDetails.ABI.SPIN_WHEEL_ABI, contractDetails[chainName].SPIN_WHEEL);
        }catch(err){
            console.log("==err======",err)
        }
       
    }
    if (contractType == "ERC20") {
        contractMethods = new web3.eth.Contract(contractDetails.ABI.ERC20_ABI, contractDetails[chainName][tokenName]);
    }
    return contractMethods;
}

export async function approveERC20Token(chainName, tokenName, amount, spenderAddress) {
    const contractMethods = await CreateNewContract("ERC20", chainName, tokenName);
    const userWallet = localStorage.getItem("connected_wallet");
    const approve = await contractMethods.methods.approve(spenderAddress, amount).send({ from: userWallet });
    return approve;

}

export async function checkToken(chainName, tokenName, spenderAddress) {
    const contractMethods = await CreateNewContract("ERC20", chainName, tokenName);
    const userWallet = localStorage.getItem("connected_wallet");
    const approve = await contractMethods.methods.allowance(userWallet,spenderAddress).call();
    return approve;

}


// tokenContract.methods.allowance(ownerAddress, spenderAddress).call((error, result) => {
//     if (error) {
//         console.error('Error:', error);
//     } else {
//         console.log('Allowance:', result);
//     }
// });
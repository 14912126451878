import React from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import Fab from "@material-ui/core/Fab";
import sliderImage from "../assets/images/game-one.png"
import sliderImagetwo from "../assets/images/game-two.png"
import sliderImagethree from "../assets/images/game-three.png"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from "react-bootstrap";
export const data = [
  {
    cover: sliderImage,
    title: "Spin The Wheel",
    casinoType: "spinWheel"
  },
  {
    cover: sliderImagetwo,
    title: "Prediction Market",
    casinoType: "prediction"
  },
  {
    cover: sliderImagethree,
    title: "Jumbo Slot Machine ",
    casinoType: "jumboSlot"
  },

  {
    cover: sliderImage,
    title: "Spin The Wheel",
    casinoType: "spinWheel"
  },
  {
    cover: sliderImagetwo,
    title: "Prediction Market",
    casinoType: "prediction"
  },
  {
    cover: sliderImagethree,
    title: "Jumbo Slot Machine",
    casinoType: "jumboSlot"
  },
];

export default function ResponsiveCarousel(props) {
  console.log("ResponsiveCarousel",props);
  const ref = React.useRef();
  function casinoRender(casinoType) {
    switch (casinoType) {
      case "spinWheel":
        return <Button type="button" variant="unset" className="try-luck-btn"onClick={() => props.setShowSpinner(true)} >Try Luck </Button>
      case "prediction":
        return <Button type="button" variant="unset" className="try-luck-btn" onClick={props.predictionModalShow} >Try Luck </Button>
      default:
        return <Button  type="button" variant="unset" className="try-luck-btn disable-btn" >Coming Soon</Button>
    }
  }
 const casinoData = data.map(item=> {return{...item,button:casinoRender(item.casinoType)}});
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <ResponsiveContainer 
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 7;
          if (parentWidth <= 1440) currentVisibleSlide = 3;
          if (parentWidth <= 1080) currentVisibleSlide = 1;
          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              carouselWidth={parentWidth}
              data={casinoData}
              loop={true}
              autoplay={true}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={3}
              useGrabCursor
              transitionTime={1000}
              fadeDistance={0.1}
            />
          );
        }}
      />
      <>
        <Fab
          style={{ position: "absolute", top: "40%", left: 10, zIndex: 10 }}
          size="small"
          color="primary"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <ArrowBackIosIcon />
        </Fab>
        <Fab
          style={{ position: "absolute", top: "40%", right: 10, zIndex: 10 }}
          size="small"
          color="primary"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <ArrowForwardIosIcon />
        </Fab>
      </>
    </div>
  );
}

export const Card = React.memo(function (props) {
  const { data, dataIndex,isCenterSlide } = props;
  const { cover,title,casinoType,button } = data[dataIndex];
  return (
    <div
      className= {  isCenterSlide ?"my-slide-component " : "my-slide-component active"}
     >
      <div className="game-slider-image">
        <img
          draggable={false}
          src={cover}
          alt="img"
        />
      </div>
      <div className="game-slider-content">
        <h5>{title}</h5>
        {button}
      </div>
    </div>
  );
});

import { useState } from "react";
import { Accordion, Button, Col, Container, Modal, Row } from "react-bootstrap"
import { useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
// import { Navigation } from 'swiper/modules';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { PieChart } from '@mui/x-charts/PieChart';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Handleconnect, formatChainAsNum, formatAddress, formatBalance } from "../common/connectWallet";
import { divideBy } from "../config/config";
import Box from '@mui/material/Box';
import { Link, useParams, useNavigate } from "react-router-dom";
import CountDown from './Countdown';
import Spinner from "./Spinner";
import ResponsiveCarousel from "./Slider";
import { apiService } from "../service/api.service"
import swal from "sweetalert";
import Prediction from "./Prediction";
import Jumbo from "./Jumbo";
import Loader from "../common/Loader";
import PredictionMarket from "./Home/PredictionMarket";
import SpinGame from "./Home/SpinGame";
import JumboGame from "./Home/JumboGame";
import { setWalletAddress, setwalletConnected, setwalletBalance } from '../store/index_slice'
import { useSelector, useDispatch } from 'react-redux'
const Home = () => {
    const [show, setShow] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showJumbo, setShowJumbo] = useState(false);
    const address = useParams()
    const navigate = useNavigate()
    const handleClose = () => setShow(false);
    // const handleShowConnect = () => setShowconnect(true);
    // alert(window.location.pathname.split("/")[1])
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [predictions, setpredictions] = useState([])
    const [sliderIndex, setsliderIndex] = useState()
    const [limit, setlimit] = useState(9)
    const [totalRecords, settotalRecords] = useState(0)
    const [price, setPrice] = useState(1)
    const [payoutprediction, setpayoutprediction] = useState(0);
    const [duration, setduration] = useState("")
    const [type, settype] = useState("")
    const [value, setValue] = useState([30, 60]);
    function valuetext(value) {
        // pricePrediction(value)
        return `${value}`;
    }
    const connectWallet = async () => {

        let accounts = await Handleconnect()
        console.log("====accounts====", accounts);
        if (accounts == "meta_error") {
            // handleClose()
        } else {
            if (accounts) {
                dispatch(setwalletConnected(true))
                dispatch(setWalletAddress(accounts))
                swal("Success", "Wallet connected successfully", "success").then(() => {
                    window.location.reload()
                });
                // handleShowConnect()
                // setShow(false)
            }
        }

    }
    useEffect(() => {

        if (window.location.pathname.split("/")[1] == "prediction" && window.location.pathname.split("/")[2] != "undefined") {
            predictionModalShow()
        }
        if (window.location.pathname.split("/")[2] == "undefined") {
            window.history.pushState(null, '', "/");
        }
    }, []);
    function DiscreteSlider() {
        return (
            <RangeSlider
                marks
                min={1}
                max={100}
                className="single-thumb"
                defaultValue={[0, 1]}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                step={1}
                onInput={valuetext}
            // thumbsDisabled={[false, false]}
            />
        );
    }

    const predictionModalShow = () => {
        get_prediction(limit, duration, type)
        setShow(true);
    }

    async function get_prediction(limit, duration, type) {
        setLoader(true);

        try {
            let prediction_contract_address = window.location.pathname.split("/")[2]
            const response = await apiService.get_prediction(limit, duration, type,prediction_contract_address);

            if (response.status == 200) {
                console.log("====param====", address.contract_address)
                if (address.contract_address) {
                    const keyToFind = "contract_address";
                    const valueToFind = address.contract_address;

                    const index = response.data.data.list.findIndex(obj => obj[keyToFind] === valueToFind);

                    setsliderIndex(index)
                } else {
                    setsliderIndex(0)
                    address.contract_address = response.data.data.list[0].contract_address




                }
                settotalRecords(response.data.data.totalRecords);
                setpredictions(response.data.data.list)
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
            }
        }
    }


    return (
        <>
            {/* <Loader /> */}
            <section className="hero-banner">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} lg={3}></Col>
                        <Col md={12} lg={9}>
                            <div className="hero-banner-content-right">
                                <h1 className="russo-one"> Join the Future of <span>Betting</span> with Defi!</h1>
                                <p>Experience the thrill of decentralized <br />
                                    betting on the blockchain.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="banner-coins">
                    <img src={require("../assets/images/coins.png")} alt="img" />
                </div>
            </section>

            <section className="games-option">
                <Container>
                    <ResponsiveCarousel predictionModalShow={predictionModalShow} setShowSpinner={setShowSpinner} setShowJumbo={setShowJumbo} />
                </Container>
            </section>

            <section className="marque-area">
                <marquee width="100%" direction="left" height="100px">
                    <div className="marque-content">
                        <h2>Betting</h2>
                        <img src={require("../assets/images/card-icon.png")} alt="img" />
                        <h2>Casino</h2>
                        <img src={require("../assets/images/card-icon.png")} alt="img" />
                        <h2>Betting</h2>
                        <img src={require("../assets/images/card-icon.png")} alt="img" />
                        <h2>Casino</h2>
                        <img src={require("../assets/images/card-icon.png")} alt="img" />
                        <h2>Betting</h2>
                        <img src={require("../assets/images/card-icon.png")} alt="img" />
                        <h2>Casino</h2>
                        <img src={require("../assets/images/card-icon.png")} alt="img" />
                        <h2>Betting</h2>
                        <img src={require("../assets/images/card-icon.png")} alt="img" />
                    </div>
                </marquee>
            </section>

            <section className="feature-area">
                <Container>
                    <Swiper
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            420: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            480: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            575: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                            1400: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }}
                        slidesPerView={4}
                        spaceBetween={20}
                        cssMode={true}
                        navigation={true}
                        pagination={true}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        className="mySwiper"
                    >
                        <SwiperSlide>

                            <div className="features-area">
                                <div className="feature-ico">
                                    <img src={require("../assets/images/star.png")} alt="img" />
                                </div>
                                <h6>Participate in Exciting Prediction Markets</h6>
                            </div>


                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="features-area">
                                <div className="feature-ico">
                                    <img src={require("../assets/images/star.png")} alt="img" />
                                </div>
                                <h6>Spin-the-Wheel for Instant Prizes</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="features-area">
                                <div className="feature-ico">
                                    <img src={require("../assets/images/star.png")} alt="img" />
                                </div>
                                <h6>Seamless Wallet Integration with MetaMask</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="features-area">
                                <div className="feature-ico">
                                    <img src={require("../assets/images/star.png")} alt="img" />
                                </div>
                                <h6>Decentralized and Transparent Betting Platform</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="features-area">
                                <div className="feature-ico">
                                    <img src={require("../assets/images/star.png")} alt="img" />
                                </div>
                                <h6>Earn Rewards with Every Bet</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="features-area">
                                <div className="feature-ico">
                                    <img src={require("../assets/images/star.png")} alt="img" />
                                </div>
                                <h6>Explore Diverse Betting Options</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="features-area">
                                <div className="feature-ico">
                                    <img src={require("../assets/images/star.png")} alt="img" />
                                </div>
                                <h6>24/7 Access to Betting Opportunities</h6>
                            </div></SwiperSlide>
                        <SwiperSlide>
                            <div className="features-area">
                                <div className="feature-ico">
                                    <img src={require("../assets/images/star.png")} alt="img" />
                                </div>
                                <h6>Enhanced Security with Blockchain Technology</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="features-area">
                                <div className="feature-ico">
                                    <img src={require("../assets/images/star.png")} alt="img" />
                                </div>
                                <h6>User-Friendly Interface for Effortless Betting</h6>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </Container>
            </section>


    

            <PredictionMarket predictionModalShow={predictionModalShow} />
            <SpinGame setShowSpinner={setShowSpinner} />
            <JumboGame setShowJumbo={setShowJumbo} />

            <section className="about-area">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={12} lg={10}>
                            <div className="about-content-area">
                                <h2 className="russo-one">About Defi Betting</h2>
                                <p>Defi Betting is a decentralized prediction market and spin-the-wheel dApp built on the Polygon (MATIC) blockchain. We offer users the opportunity to participate in transparent and automated betting activities using USDC stablecoins, all secured by blockchain technology.</p>
                                <img src={require("../assets/images/about-img.png")} alt="img"  data-aos="zoom-in-up" data-aos-duration="2000"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="why-choose-area">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <div className="why-choose-content">
                                <img src={require("../assets/images/why-choose-star.png")} alt="img"  data-aos="fade-down" data-aos-duration="2000"/>
                                <h2 className="russo-one">Why Choose Defi Betting?</h2>
                                <p>With Defi Betting, you can enjoy the benefits of decentralized finance while participating in betting activities. Our platform offers transparency, security, and efficiency, thanks to blockchain technology. Say goodbye to centralized intermediaries and hello to a new era of decentralized betting.</p>
                                {localStorage.getItem("is_walletConnected") ?
                                    <></> :
                                    <button type="button" variant="unset" onClick={() => connectWallet()}>Connect Wallet</button>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="winners-area">
                <Container>
                    <h2 className="russo-one"  data-aos="flip-left" data-aos-duration="2000">Top Winners</h2>
                    <Swiper
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            420: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            480: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            575: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                            1400: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }}
                        slidesPerView={4}
                        spaceBetween={20}
                        cssMode={true}
                        navigation={true}
                        pagination={true}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="winners-area-content">
                                <div className="game-image">
                                    <img src={require("../assets/images/prediction-img.png")} alt="" />
                                </div>
                                <div className="winners-info">
                                    <h6 class="russo-one">Prediction Market </h6>
                                    <p>2.000USDC</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="winners-area-content">
                                <div className="game-image">
                                    <img src={require("../assets/images/spinner.png")} alt="" />
                                </div>
                                <div className="winners-info">
                                    <h6 class="russo-one">Spin to Win</h6>
                                    <p>2.000USDC</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="winners-area-content">
                                <div className="game-image">
                                    <img src={require("../assets/images/jumbo.png")} alt="" />
                                </div>
                                <div className="winners-info">
                                    <h6 class="russo-one">Spin to Win Big </h6>
                                    <p>2.000USDC</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="winners-area-content">
                                <div className="game-image">
                                    <img src={require("../assets/images/prediction-img.png")} alt="" />
                                </div>
                                <div className="winners-info">
                                    <h6 class="russo-one">Prediction Market </h6>
                                    <p>2.000USDC</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="winners-area-content">
                                <div className="game-image">
                                    <img src={require("../assets/images/spinner.png")} alt="" />
                                </div>
                                <div className="winners-info">
                                    <h6 class="russo-one">Spin to Win</h6>
                                    <p>2.000USDC</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="winners-area-content">
                                <div className="game-image">
                                    <img src={require("../assets/images/jumbo.png")} alt="" />
                                </div>
                                <div className="winners-info">
                                    <h6 class="russo-one">Spin to Win Big </h6>
                                    <p>2.000USDC</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </Container>
            </section>

            <Prediction showpop={show} setShowpop={setShow} predictions={predictions} setpredictions={setpredictions} address={address ? address.contract_address : null} sliderIndex={sliderIndex} setsliderIndex={setsliderIndex} />
            <Spinner show={showSpinner} setShow={(e) => setShowSpinner(e)} />
            <Jumbo show={showJumbo} setShow={(e) => setShowJumbo(e)} />

        </>

    )
}


export default Home
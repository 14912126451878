import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CommonLayout from "../common/CommonLayout";
import Home from "../component/Home";
import TransactionManagement from "../component/TransactionManagement";
import PredictionDetail from "../component/PredictionDetail";

const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<CommonLayout>{<Home />}</CommonLayout>}
        ></Route>
      <Route
          path="/prediction/:contract_address"
          element={<CommonLayout>{<Home />}</CommonLayout>}
        ></Route>
         <Route
          path="/transactions"
          element={<CommonLayout>{<TransactionManagement />}</CommonLayout>}
        ></Route>
           <Route
          path="/predictions"
          element={<CommonLayout>{<PredictionDetail/>}</CommonLayout>}
        ></Route>
      </Routes>
    </Router>
  );
};

export default Routing;

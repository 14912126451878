import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";

const SpinGame = (props) => {
    const [showCongrats, setShow] = useState(false);

    const handleCloseCongrats = () => setShow(false);
    const handleShowCongrats = () => setShow(true);

  


    return (
        <>
            <section className="prediction-area spinner-area">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <div className="prediction-area-right"  data-aos="fade-right" data-aos-duration="2000">
                                <img src={require("../../assets/images/spinner.png")} alt="img" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="prediction-area-left">
                                <h2 className="russo-one">Spin to Win</h2>
                                <p>Feeling lucky? Try your luck with our spin-the-wheel games and win exciting prizes instantly. Simply select a number, place your bet, and spin the wheel for a chance to win big. Enjoy the thrill of chance in a decentralized environment.</p>
                                <button onClick={() => props.setShowSpinner(true)}>
                                    Try Luck
                                    <div className="star-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="star-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="star-3">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="star-4">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="star-5">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="star-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>

{/* 
            <Button variant="primary" onClick={handleShowLose}>
                Lose
            </Button> */}

            {/* <Button variant="primary" onClick={handleShowCongrats}>
                Congrats
            </Button> */}

            <Modal show={showCongrats} onHide={handleCloseCongrats} centered className="congrats-popup congratulation-popup">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <img src={require("../../assets/images/congrats.png")} alt="img" />
                    <h5 className="russo-one">You Won!</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="unset">
                        Play Again
                    </Button>

                </Modal.Footer>
            </Modal>


           {/* lose */}
               

        </>
    );
};
export default SpinGame;
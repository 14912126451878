import { createContext } from "react";
import swal from "sweetalert";
import { SPIN_WHEEL_ABI } from "./contractAbi";
import { tokenAbi } from "./tokenAbi";
export const MyContext = createContext();
//staging Live

//  export const apiUrl = "https://api.betfolio.co/api/v1"
//  export const baseUrl = "https://api.betfolio.co/"
//  export const chainId = 137;
//  export const tokenAddress = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";//live mainnet
// export const consttransactionurl =  "https://mumbai.polygonscan.com/tx/";
// export const divideBy = 1000000;
// export const decimalTo = 6;
//staging Live

//staging start
export const apiUrl = "https://defi-betting-api.ecomempire.in/api/v1"
export const baseUrl = "https://defi-betting-api.ecomempire.in/"
export const chainId = 80001;
export const aproveToken = 100000000;
export const tokenAddress = "0x3ce8246f04c355eaf1f9b88b0c72e7a90ecaf7a9";
export const contractDetails = {
    MATIC: {
        SPIN_WHEEL: "0xA816Ec7d942e7db2E3226d89f9AEe423AC34Cee2",
        USDC6: "0x3ce8246f04c355eaf1f9b88b0c72e7a90ecaf7a9"
    },
    ABI: {
        SPIN_WHEEL_ABI: SPIN_WHEEL_ABI,
        ERC20_ABI: tokenAbi
    }
};
export const consttransactionurl = "https://mumbai.polygonscan.com/tx/";
export const divideBy = 1000000;
export const decimalTo = 6;
//staging End


export const gasFee = 21000
export const gasFeePercent = 1.9

export const httpproviderPOLY = "https://polygon-mumbai.g.alchemy.com/v2/ZbBgRPLIBdkabBTDqNGXe03I5Lh_pYmO"


export const indexcubeMetamaskUrl = "https://metamask.app.link/dapp/betfolio.co//"
export const Unauthorized = () => {
    swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
        localStorage.clear()
        window.location.href = "/";
        console.log('OK button clicked after error alert');
    })
}

import { useState } from "react";
import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { PieChart } from '@mui/x-charts/PieChart';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import Box from '@mui/material/Box';
import CountDown from './Countdown';

const Jumbo = ({ show, setShow }) => {

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)} centered size="xl" className="spinner-popup">
                <Modal.Header closeButton>
                    <Modal.Title> Jumbo Slot Machine</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="align-items-center slider-area-popup">
                        <Col md={6}>

                            <div className="prediction-swiper-left">
                             
                                <div className="bet-amount-area">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Bet Amount(USDC):</Form.Label>
                                            <Form.Control type="text" placeholder="Enter in USDC" />
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="spinning-option">
                                    <h6>Option Selection Leads to Slot Machine Interface:</h6>
                                    <Form>
                                        {['radio'].map((type) => (
                                            <div key={`default-${type}`} >
                                                <Form.Check // prettier-ignore
                                                    type={type}
                                                    id={`default-${type}`}
                                                    label={`Tier 1 (1 try)`}
                                                />


                                            </div>

                                        ))}
                                        <div className="jumbo-detail">
                                            <ul>
                                                <li>If there will be three match user will earn 10x of bet amount </li>
                                                <li>If there will be two match user will win 7x of bet amount</li>
                                           </ul>
                                        </div>
                                        {['radio'].map((type) => (
                                            <div key={`default-${type}`} >
                                                <Form.Check // prettier-ignore
                                                    type={type}
                                                    id={`default-${type}`}
                                                    label={`Tier 2 (2 try)`}
                                                />


                                            </div>

                                        ))}
                                            <div className="jumbo-detail">
                                            <ul>
                                                <li>If there will be three matches, the user will earn 5x of bet amount for each try.
</li>
                                                <li>If there will be two matches, the user will win 3x of the bet amount for each try.</li>
                                           </ul>
                                        </div>
                                        {['radio'].map((type) => (
                                            <div key={`default-${type}`} >
                                                <Form.Check // prettier-ignore
                                                    type={type}
                                                    id={`default-${type}`}
                                                    label={`Tier 1 (3 try)`}
                                                />


                                            </div>

                                        ))}
                                            <div className="jumbo-detail">
                                            <ul>
                                                <li>If there will be three matches, the user will earn 2x of bet amount for each try.</li>
                                                <li>If there will be two match user will win 1.5x of bet amount for each try.
</li>
                                           </ul>
                                        </div>
                                    </Form>
                                </div>
                                <Button type="button" variant="unset" className="mt-3">Start</Button>
                            </div>
                        </Col>
                        <Col md={6}>


                        </Col>
                    </Row>
                </Modal.Body>

            </Modal>
        </>
    );
};


export default Jumbo
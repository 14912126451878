import React from "react";

const Loader = () => {
    return (
        <>
            <div className="outer-loader">
            <span class="main-loader"></span>
            </div>
        
        </>
    );
};
export default Loader
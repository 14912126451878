import { Button, Col, Container, Row, Form } from "react-bootstrap";
import Select from "react-select";

const PredictionDetail = () => {
    const options = [
        { value: "", label: "All" },
        { value: "DAY", label: "1 day" },
        { value: "WEEK", label: "1 week" },
        { value: "MONTH", label: "1 month" },
    ];

    const optionsFilter = [
        { value: "", label: "All" },
        { value: "Upcoming", label: "Upcoming" },
        { value: "In-Progress", label: "In-Progress" },
        { value: "Past", label: "Past" },
    ];
    return (
        <>
            <section className="prediction-detail-area">
                <Container>
                    <div className="prediction-market-area">
                        <Row className="align-items-center mb-5">
                            <Col md={12} lg={6} xl={6}>
                                <h2>Explore Prediction </h2>
                            </Col>
                            <Col md={12} lg={6} xl={6}>
                                <div className="explore-top-filter">

                                    <Form.Group
                                        className="filter-area-top"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Filter By:</Form.Label>
                                        <Select
                                            options={options} placeholder="All" />
                                    </Form.Group>
                                    <Form.Group
                                        className="filter-area-top"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Prediction:</Form.Label>
                                        <Select
                                            options={optionsFilter} placeholder="All" />
                                    </Form.Group>
                                </div>

                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={6} lg={6} xl={4} className="mb-4">
                                <div className="prediction-box">
                                    <div className="prediction-market-inner">
                                        <div className="prediction-market-left">
                                            <img src={require("../assets/images/dummy.png")} alt="img" />
                                        </div>
                                        <div className="prediction-market-right">
                                            <div className="bg-vector">
                                                <img src={require("../assets/images/bg-vector.svg").default} alt="img" />
                                                <p>1 Hour</p>
                                            </div>
                                            <h5>Cosmos Eco will outperform L2 Index</h5>
                                            <p>Cosmos Eco will outperform L2 Index</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><i class="fa fa-check" aria-hidden="true"></i>True
                                            <p>1.00 USDC<span>(1 bets)</span></p></li>
                                        <li><i class="fa fa-times" aria-hidden="true"></i>False
                                            <p>0.00 USDC<span>(0 bets)</span> </p></li>
                                    </ul>
                                    <div className="prdiction-box-bottom">
                                        <p> Total : <span>5.00 USDC</span>(2 bets)</p>
                                        <Button type="button" variant="unset" >Predict</Button>
                                    </div>
                                </div>

                            </Col>
                            <Col md={6} lg={6} xl={4} className="mb-4">
                                <div className="prediction-box">
                                    <div className="prediction-market-inner">
                                        <div className="prediction-market-left">
                                            <img src={require("../assets/images/dummy.png")} alt="img" />
                                        </div>
                                        <div className="prediction-market-right">
                                            <div className="bg-vector">
                                                <img src={require("../assets/images/bg-vector.svg").default} alt="img" />
                                                <p>1 Hour</p>
                                            </div>
                                            <h5>Cosmos Eco will outperform L2 Index</h5>
                                            <p>Cosmos Eco will outperform L2 Index</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><i class="fa fa-check" aria-hidden="true"></i>True
                                            <p>1.00 USDC<span>(1 bets)</span></p></li>
                                        <li><i class="fa fa-times" aria-hidden="true"></i>False
                                            <p>0.00 USDC<span>(0 bets)</span> </p></li>
                                    </ul>
                                    <div className="prdiction-box-bottom">
                                        <p> Total : <span>5.00 USDC</span>(2 bets)</p>
                                        <Button type="button" variant="unset" >Predict</Button>
                                    </div>
                                </div>

                            </Col>
                            <Col md={6} lg={6} xl={4} className="mb-4">
                                <div className="prediction-box">
                                    <div className="prediction-market-inner">
                                        <div className="prediction-market-left">
                                            <img src={require("../assets/images/dummy.png")} alt="img" />
                                        </div>
                                        <div className="prediction-market-right">
                                            <div className="bg-vector">
                                                <img src={require("../assets/images/bg-vector.svg").default} alt="img" />
                                                <p>1 Hour</p>
                                            </div>
                                            <h5>Cosmos Eco will outperform L2 Index</h5>
                                            <p>Cosmos Eco will outperform L2 Index</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><i class="fa fa-check" aria-hidden="true"></i>True
                                            <p>1.00 USDC<span>(1 bets)</span></p></li>
                                        <li><i class="fa fa-times" aria-hidden="true"></i>False
                                            <p>0.00 USDC<span>(0 bets)</span> </p></li>
                                    </ul>
                                    <div className="prdiction-box-bottom">
                                        <p> Total : <span>5.00 USDC</span>(2 bets)</p>
                                        <Button type="button" variant="unset" >Predict</Button>
                                    </div>
                                </div>

                            </Col>

                            <Col md={6} lg={6} xl={4} className="mb-4">
                                <div className="prediction-box">
                                    <div className="prediction-market-inner">
                                        <div className="prediction-market-left">
                                            <img src={require("../assets/images/dummy.png")} alt="img" />
                                        </div>
                                        <div className="prediction-market-right">
                                            <div className="bg-vector">
                                                <img src={require("../assets/images/bg-vector.svg").default} alt="img" />
                                                <p>1 Hour</p>
                                            </div>
                                            <h5>Cosmos Eco will outperform L2 Index</h5>
                                            <p>Cosmos Eco will outperform L2 Index</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><i class="fa fa-check" aria-hidden="true"></i>True
                                            <p>1.00 USDC<span>(1 bets)</span></p></li>
                                        <li><i class="fa fa-times" aria-hidden="true"></i>False
                                            <p>0.00 USDC<span>(0 bets)</span> </p></li>
                                    </ul>
                                    <div className="prdiction-box-bottom">
                                        <p> Total : <span>5.00 USDC</span>(2 bets)</p>
                                        <Button type="button" variant="unset" >Predict</Button>
                                    </div>
                                </div>

                            </Col>
                            <Col md={6} lg={6} xl={4} className="mb-4">
                                <div className="prediction-box">
                                    <div className="prediction-market-inner">
                                        <div className="prediction-market-left">
                                            <img src={require("../assets/images/dummy.png")} alt="img" />
                                        </div>
                                        <div className="prediction-market-right">
                                            <div className="bg-vector">
                                                <img src={require("../assets/images/bg-vector.svg").default} alt="img" />
                                                <p>1 Hour</p>
                                            </div>
                                            <h5>Cosmos Eco will outperform L2 Index</h5>
                                            <p>Cosmos Eco will outperform L2 Index</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><i class="fa fa-check" aria-hidden="true"></i>True
                                            <p>1.00 USDC<span>(1 bets)</span></p></li>
                                        <li><i class="fa fa-times" aria-hidden="true"></i>False
                                            <p>0.00 USDC<span>(0 bets)</span> </p></li>
                                    </ul>
                                    <div className="prdiction-box-bottom">
                                        <p> Total : <span>5.00 USDC</span>(2 bets)</p>
                                        <Button type="button" variant="unset" >Predict</Button>
                                    </div>
                                </div>

                            </Col>
                            <Col md={6} lg={6} xl={4} className="mb-4">
                                <div className="prediction-box">
                                    <div className="prediction-market-inner">
                                        <div className="prediction-market-left">
                                            <img src={require("../assets/images/dummy.png")} alt="img" />
                                        </div>
                                        <div className="prediction-market-right">
                                            <div className="bg-vector">
                                                <img src={require("../assets/images/bg-vector.svg").default} alt="img" />
                                                <p>1 Hour</p>
                                            </div>
                                            <h5>Cosmos Eco will outperform L2 Index</h5>
                                            <p>Cosmos Eco will outperform L2 Index</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><i class="fa fa-check" aria-hidden="true"></i>True
                                            <p>1.00 USDC<span>(1 bets)</span></p></li>
                                        <li><i class="fa fa-times" aria-hidden="true"></i>False
                                            <p>0.00 USDC<span>(0 bets)</span> </p></li>
                                    </ul>
                                    <div className="prdiction-box-bottom">
                                        <p> Total : <span>5.00 USDC</span>(2 bets)</p>
                                        <Button type="button" variant="unset" >Predict</Button>
                                    </div>
                                </div>

                            </Col>
                            <Col md={6} lg={6} xl={4} className="mb-4">
                                <div className="prediction-box">
                                    <div className="prediction-market-inner">
                                        <div className="prediction-market-left">
                                            <img src={require("../assets/images/dummy.png")} alt="img" />
                                        </div>
                                        <div className="prediction-market-right">
                                            <div className="bg-vector">
                                                <img src={require("../assets/images/bg-vector.svg").default} alt="img" />
                                                <p>1 Hour</p>
                                            </div>
                                            <h5>Cosmos Eco will outperform L2 Index</h5>
                                            <p>Cosmos Eco will outperform L2 Index</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><i class="fa fa-check" aria-hidden="true"></i>True
                                            <p>1.00 USDC<span>(1 bets)</span></p></li>
                                        <li><i class="fa fa-times" aria-hidden="true"></i>False
                                            <p>0.00 USDC<span>(0 bets)</span> </p></li>
                                    </ul>
                                    <div className="prdiction-box-bottom">
                                        <p> Total : <span>5.00 USDC</span>(2 bets)</p>
                                        <Button type="button" variant="unset" >Predict</Button>
                                    </div>
                                </div>

                            </Col>
                            <Col md={6} lg={6} xl={4} className="mb-4">
                                <div className="prediction-box">
                                    <div className="prediction-market-inner">
                                        <div className="prediction-market-left">
                                            <img src={require("../assets/images/dummy.png")} alt="img" />
                                        </div>
                                        <div className="prediction-market-right">
                                            <div className="bg-vector">
                                                <img src={require("../assets/images/bg-vector.svg").default} alt="img" />
                                                <p>1 Hour</p>
                                            </div>
                                            <h5>Cosmos Eco will outperform L2 Index</h5>
                                            <p>Cosmos Eco will outperform L2 Index</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><i class="fa fa-check" aria-hidden="true"></i>True
                                            <p>1.00 USDC<span>(1 bets)</span></p></li>
                                        <li><i class="fa fa-times" aria-hidden="true"></i>False
                                            <p>0.00 USDC<span>(0 bets)</span> </p></li>
                                    </ul>
                                    <div className="prdiction-box-bottom">
                                        <p> Total : <span>5.00 USDC</span>(2 bets)</p>
                                        <Button type="button" variant="unset" >Predict</Button>
                                    </div>
                                </div>

                            </Col>
                            <Col md={6} lg={6} xl={4} className="mb-4">
                                <div className="prediction-box">
                                    <div className="prediction-market-inner">
                                        <div className="prediction-market-left">
                                            <img src={require("../assets/images/dummy.png")} alt="img" />
                                        </div>
                                        <div className="prediction-market-right">
                                            <div className="bg-vector">
                                                <img src={require("../assets/images/bg-vector.svg").default} alt="img" />
                                                <p>1 Hour</p>
                                            </div>
                                            <h5>Cosmos Eco will outperform L2 Index</h5>
                                            <p>Cosmos Eco will outperform L2 Index</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><i class="fa fa-check" aria-hidden="true"></i>True
                                            <p>1.00 USDC<span>(1 bets)</span></p></li>
                                        <li><i class="fa fa-times" aria-hidden="true"></i>False
                                            <p>0.00 USDC<span>(0 bets)</span> </p></li>
                                    </ul>
                                    <div className="prdiction-box-bottom">
                                        <p> Total : <span>5.00 USDC</span>(2 bets)</p>
                                        <Button type="button" variant="unset" >Predict</Button>
                                    </div>
                                </div>

                            </Col>
                            

                        </Row>
                        <Button type="button" variant="unset" className="viw-more-btn">View More</Button>

                    </div>
                </Container>
            </section>

        </>
    );
};
export default PredictionDetail;
import { startTransition, useState, useRef, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  FreeMode,
  Thumbs,
} from "swiper/modules";
import {
  CreateNewContract,
  CustomMarks,
  approveERC20Token,
  checkToken,
} from "../common/CommonComponent";
// import "swiper/css";
// import "swiper/css/navigation";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
// import {
//   CreateNewContract,
//   CustomMarks,
//   approveERC20Token,
//   checkToken,
// } from "../common/CommonComponent";
import SpinWheel from "./SpinWheel";
import {
  contractDetails,
  decimalTo,
  chainId,
  aproveToken,
  tokenAddress,
} from "../config/config";
import { tokenAbi } from "../config/tokenAbi";
import { ethers } from "ethers";
import swal from "sweetalert";

import {
  setWalletAddress,
  setwalletConnected,
  setwalletBalance,
} from "../store/index_slice";
import {
  Handleconnect,
  formatChainAsNum,
  formatBalance,
} from "../common/connectWallet";

import soundRiboonFile from "../assets/sounds/riboon.mp3";

const { Web3 } = require("web3");
const Spinner = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const [chainName, setChainName] = useState("MATIC");
  const [betAmount, setBetAmount] = useState(4);
  const [spinTimes, setSpinTimes] = useState(0);
  const [betNumber, setBetNumber] = useState("");
  const [canSpinNow, setcanSpinNow] = useState(false);
  const swiperRef = useRef(null);
  const [amountErr, setAmountErr] = useState("");
  const [Isblur, setblur] = useState(false);
  const iswallet = useSelector((state) => state.reducer.walletConnected);
  const [winningAmount, setwinningAmount] = useState();
  const [spinChance, setspinChance] = useState(0);
  const [tmpArray, settmpArray] = useState();
  const [spinNumber, setspinNumber] = useState();
  const [disableAreaLeft, setdisableAreaLeft] = useState(false);
  const [disableAreaRight, setdisableAreaRight] = useState(true);
  const [selectNumberSwiper, setSelectNumberSwiper] = useState();
  const [selectNumberSwiperTmp, setSelectNumberSwiperTmp] = useState();

  const audioRiboonRef = useRef(null);
  const [isRiboonPlaying, setIsRiboonPlaying] = useState(false);
  const [tokenApproved, settokenApproved] = useState(false);
  const [showPlaceBetSteps, setShowPlaceBetSteps] = useState(false);

  useEffect(() => {
    console.log(
      "change in swiper",
      selectNumberSwiper,
      swiperRef.current?.swiper
    );
  }, [selectNumberSwiperTmp]);




  useEffect(() => {
    if(tokenApproved){
      spinTransaction()
    }
  },[tokenApproved])


  async function spinTransaction(){
    console.log("spinTransaction");
    const contractMethods = await CreateNewContract(
      "SPIN_WHEEL",
      chainName
    );

    let amount = ethers.utils.parseUnits(betAmount.toString(), decimalTo);
    // let aproveToken = ethers.utils.parseUnits("100", decimalTo);
    amount = amount.toString();
    console.log("amount---->", amount);
    const userWallet = localStorage.getItem("connected_wallet");
    // console.log(
    //   "betNumber, amount, spinTimes====",
    //   betNumber,
    //   amount,
    //   spinTimes
    // );
    const placeBetAndSpinWheel = await contractMethods.methods
      .placeBetAndSpinWheel(betNumber, amount, spinTimes)
      .send({ from: userWallet });
    if (placeBetAndSpinWheel) {
      setTimeout(() => {
        setcanSpinNow(true);
      }, 2500);

      settokenApproved(false);
      handleClosePlaceBetSteps();
      setdisableAreaLeft(true);
    }

    const {
      spinChances,
      randomNumber1,
      randomNumber2,
      randomNumber3,
      isWin,
    } = placeBetAndSpinWheel.events.BetPlaced.returnValues;
    setspinChance(Number(spinChances));
    setspinNumber(Number(spinChances) + 1);
    const temp = [
      Number(randomNumber1),
      Number(randomNumber2),
      Number(randomNumber3),
    ];
    settmpArray(temp);
    if (Number(spinChances) == 0) {
      setwinningAmount(10 * betAmount);
    } else if (Number(spinChances) == 1) {
      setwinningAmount(5 * betAmount);
    } else {
      setwinningAmount(2 * betAmount);
    }
  }


  const playRiboonSound = () => {
    if (audioRiboonRef.current) {
      audioRiboonRef.current.play();
      setIsRiboonPlaying(true);
    }
  };

  const pauseRiboonSound = () => {
    if (audioRiboonRef.current) {
      audioRiboonRef.current.pause();
      setIsRiboonPlaying(false);
    }
  };

  // const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    if (spinNumber >= 1 && canSpinNow) {
      swal({
        title: "Confirmation",
        text: `You have ${spinNumber} spin remaining. Do you wish to quit the game? If you do, you will lose your remaining spin.   `,
        icon: "warning",
        buttons: ["NO", "YES"],
      }).then((value) => {
        if (value) {
          setspinNumber();
          setcanSpinNow(false);
          setShow(false);
          setdisableAreaLeft(false);
        } else {
          console.log("NO button clicked or popup closed");
        }
      });
    } else {
      setShow(false);
    }
  };
  const tooltip = (
    <Tooltip id="tooltip">
      <p>
        Multiply your bet by 10 in a single, thrilling spin! Experience the
        excitement of instant 10x winnings.
      </p>
    </Tooltip>

    // const [show, setShow] = useState(false);
  );
  const tooltipsecond = (
    <Tooltip id="tooltipsecond">
      <p>
        {" "}
        Double the chances, double the fun! Get 5x your bet amount if the
        specified number comes up in either spin.
      </p>
    </Tooltip>
  );
  const tooltipthird = (
    <Tooltip id="tooltipthird">
      <p>Win 2x your bet amount if you win in either of the three spins. </p>
    </Tooltip>
  );

  const options = Array.from({ length: 35 }).map((item, index) => ({
    value: Number(index + 1),
    label: index + 1,
  }));

  async function switchNetwork() {
    try {
      let chain = ethers.utils.hexValue(chainId);
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain }], // chainId must be in hexadecimal numbers
      });
      return true;
    } catch (switchError) {
      // The network has not been added to MetaMask
      if (switchError.code === 4902) {
        swal("error", "Please add the Polygon network to MetaMask", "error");
      }
      return false;
      console.log("Cannot switch to the network");
    }
  }
  // let checkconnection = await checkConnection();

  // let checkconnection = await checkConnection();
  const checkConnection = () => {
    const webb3 = new Web3(window.ethereum);
    let walletConnected = true;
    return webb3.eth.getAccounts().then(async (addr) => {
      if (addr.length == 0) {
        localStorage.clear();
        handleShow();
        walletConnected = false;
      } else {
        if (
          addr[0].toLowerCase() !==
          localStorage.getItem("connected_wallet").toLowerCase()
        ) {
          swal(
            "Warning",
            "You've linked to a different wallet address. Kindly verify in MetaMask.",
            "warning"
          );
          walletConnected = false;
        } else {
          walletConnected = true;
        }
      }

      // console.log(walletConnected)
      return walletConnected;
    });
  };
  const getBalance = async () => {
    const webb3 = new Web3(window.ethereum);
    let toContract = new webb3.eth.Contract(tokenAbi, tokenAddress);

    let userBalance = await toContract.methods
      .balanceOf(localStorage.getItem("connected_wallet").toString())
      .call();

    let balance = formatBalance(userBalance.toString());

    dispatch(setwalletBalance(balance));
  };
  const handleClosePlaceBetSteps = () => {
    setblur(false);
    setShowPlaceBetSteps(false);
  };
  const handleShowPlaceBetSteps = () => {
    setblur(false);
    setShowPlaceBetSteps(true);
  };
  const connectWallet = async () => {
    if (!iswallet) {
      let accounts = await Handleconnect();
      if (accounts == "meta_error") {
        handleClose();
      } else {
        if (accounts) {
          dispatch(setwalletConnected(true));
          dispatch(setWalletAddress(accounts));
          swal({
            text: "Wallet Connected ",
            button: "OK",
            icon: "success",
          }).then(() => {
            makeTransaction();
          });

          //   setis_walletConnected(true);
          // handleClose();
          //   spinWheel()
          return true;
        } else {
          return true;
        }
      }
    }
  };
  const makeTransaction = async () => {
    try {
      if (iswallet || localStorage.getItem("is_walletConnected")) {
        let checkconnection = await checkConnection();
        if (checkconnection) {
          const webb3 = new Web3(window.ethereum);

          let CurrentchainId = await formatChainAsNum();

          if (CurrentchainId != chainId) {
            await switchNetwork();
          }
          if (!betNumber || betNumber == "") {
            swal("Error", "Please select bet Number.", "error");
            return;
          }
          const userWallet = localStorage.getItem("connected_wallet");
          let amount = ethers.utils.parseUnits(betAmount.toString(), decimalTo);
          // let aproveToken = ethers.utils.parseUnits("100", decimalTo);
          amount = amount.toString();
          console.log("amount---->", amount);
          let walletAddress = localStorage.getItem("connected_wallet");
          let toContract = new webb3.eth.Contract(tokenAbi, tokenAddress);
          console.log("before balance get---->");
          let userBalance = await toContract.methods
            .balanceOf(walletAddress.toString())
            .call();
          console.log("balance get---->", userBalance);
          // let walletconnect = await  connectWallet()

          let balance = formatBalance(userBalance.toString());
          console.log("======balance===", balance);
          if (parseFloat(balance) < parseFloat(betAmount)) {
            swal("Error", "You don't have sufficient balance.", "error");
            return;
          }
          handleShowPlaceBetSteps();
          let PreapprovedToken = await checkToken(
            chainName,
            "USDC6",
            contractDetails[chainName].SPIN_WHEEL
          );
          console.log("checkTokenn---->", parseInt(PreapprovedToken));
          if (parseInt(amount) > parseInt(PreapprovedToken)) {
            const approvedToken = await approveERC20Token(
              chainName,
              "USDC6",
              aproveToken,
              contractDetails[chainName].SPIN_WHEEL
            );
            console.log("approveToken", approvedToken);
            settokenApproved(true);
          } else {
            setTimeout(() => {
              settokenApproved(true);
            }, 2000);
          }
          // console.log("placeBetAndSpinWheel", placeBetAndSpinWheel);
        } else {
          await connectWallet();
        }
      } else {
        await connectWallet();
      }
    } catch (error) {
      handleClosePlaceBetSteps();
      console.log("error--------->", error);
      if (error.toString().includes("User denied transaction signature")) {
        swal("Error", "User denied transaction signature.", "error");
      }
      if (
        error.toString().includes("Transaction has been reverted by the EVM")
      ) {
        swal(
          "Error",
          "Something Went wrong.Transaction has been reverted by the EVM",
          "error"
        );
      }
    }
  };
  const handleNextSlide = (e) => {
    console.log("Next slide reached!", e.activeIndex);
    setSelectNumberSwiperTmp(new Date());
    setSelectNumberSwiper(swiperRef.current.swiper);
    playRiboonSound();
    // swiperRef.current.swiper.slideTo(e.activeIndex+1);
  };

  const handleSlideBtn = (isNext) => {
    console.log(
      "swiperRef.current",
      swiperRef.current,
      swiperRef.current.swiper
    );
    if (isNext) {
      swiperRef.current.swiper.slideTo(
        swiperRef.current.swiper.activeIndex + 4
      );
    } else {
      swiperRef.current.swiper.slideTo(
        swiperRef.current.swiper.activeIndex - 4
      );
    }
    setSelectNumberSwiper(new Date());
    setSelectNumberSwiper(swiperRef.current.swiper);
  };
  return (
    <>
      <audio ref={audioRiboonRef}>
        <source src={soundRiboonFile} type="audio/mpeg" />
      </audio>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="xl"
        className={
          Isblur
            ? "isBlur spinner-popup spin-wheel-popup"
            : "spinner-popup spin-wheel-popup"
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>Spin the Wheel</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="align-items-center slider-area-popup">
            <Col md={6}>
              {/* {showSpinWheel ? <SpinWheel chainName={chainName} betAmount={betAmount} spinTimes={spinTimes} betNumber={betNumber} setBetNumber={setBetNumber} /> : */}

              <div
                className={
                  disableAreaLeft
                    ? "prediction-swiper-left prediction-content disable-area"
                    : "prediction-swiper-left prediction-content"
                }
              >
                <section className="spin-no-area">
                  <label>Select Number</label>
                  <Swiper
                    breakpoints={{
                      320: {
                        slidesPerView: 5,
                        spaceBetween: 3,
                      },
                      420: {
                        slidesPerView: 7,
                        spaceBetween: 7,
                      },
                      480: {
                        slidesPerView: 10,
                        spaceBetween: 4,
                      },
                      575: {
                        slidesPerView: 12,
                        spaceBetween: 4,
                      },
                      640: {
                        slidesPerView: 15,
                        spaceBetween: 4,
                      },
                      768: {
                        slidesPerView: 7,
                        spaceBetween: 4,
                      },

                      988: {
                        slidesPerView: 7,
                        spaceBetween: 4,
                      },
                      989: {
                        slidesPerView: 7,
                        spaceBetween: 4,
                      },
                      990: {
                        slidesPerView: 9,
                        spaceBetween: 4,
                      },
                      991: {
                        slidesPerView: 9,
                        spaceBetween: 4,
                      },
                      992: {
                        slidesPerView: 7,
                        spaceBetween: 4,
                      },

                      1024: {
                        slidesPerView: 10,
                        spaceBetween: 4,
                      },
                      1400: {
                        slidesPerView: 10,
                        spaceBetween: 4,
                      },
                    }}
                    ref={swiperRef}
                    slidesPerView={10}
                    spaceBetween={4}
                    cssMode={true}
                    navigation={false}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="mySwiper"
                    onSlideChange={(e) => handleNextSlide(e)}
                  >
                    {Array.from({ length: 35 }).map((item, index) => {
                      return (
                        <SwiperSlide>
                          <div
                            className={
                              betNumber == index + 1
                                ? "chooser-spin-no active"
                                : "chooser-spin-no"
                            }
                            onClick={() => setBetNumber(index + 1)}
                          >
                            {index + 1}
                          </div>
                        </SwiperSlide>
                      );
                    })}
                    {console.log(
                      "isBeginning",
                      selectNumberSwiper?.isBeginning
                    )}
                    {console.log("isEnd", selectNumberSwiper?.isEnd)}

                    <div
                      className={
                        selectNumberSwiper?.isBeginning
                          ? "swiper-button-prev disable-area"
                          : "swiper-button-prev"
                      }
                      onClick={() => handleSlideBtn(false)}
                    ></div>
                    <div
                      className={
                        selectNumberSwiper?.isEnd
                          ? "swiper-button-next disable-area"
                          : "swiper-button-next"
                      }
                      onClick={() => handleSlideBtn(true)}
                    ></div>
                  </Swiper>
                </section>

                <div className="bet-amount-area">
                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Bet Amount(USDC):</Form.Label>
                      <CustomMarks setValue={setBetAmount} value={betAmount} />
                    </Form.Group>
                  </Form>
                </div>
                <div className="spinning-option spin-wheel-option">
                  <h6>Spin Allocation Options for User Viewing:</h6>
                  <Form>
                    <div className="spin-allocation-inner">
                        <span onClick={(e) => setSpinTimes(0)}>
                          <Form.Check
                            type={"radio"}
                            value={0}
                            checked={spinTimes == 0}
                            onChange={(event) => setSpinTimes(event.target.value)}
                            label={`1 Spin: 10x of the bet amount `}
                          />
                      </span>
                      <OverlayTrigger placement="top" overlay={tooltip}>
                        <Button bsStyle="default">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </Button>
                      </OverlayTrigger>
                    </div>

                    <div className="spin-allocation-inner">
                    <span onClick={(e) => setSpinTimes(1)}>
                        <Form.Check
                          type={"radio"}
                          value={1}
                          checked={spinTimes == 1}
                          onChange={(event) => setSpinTimes(event.target.value)}
                          label={`2 Spin: 5x of the bet amount`}
                        />
                      </span>
                      <OverlayTrigger placement="top" overlay={tooltipsecond}>
                        <Button bsStyle="default">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </Button>
                      </OverlayTrigger>
                    </div>

                    <div className="spin-allocation-inner">
                    <span onClick={(e) => setSpinTimes(2)}>
                      <Form.Check
                        type={"radio"}
                        value={2}
                        checked={spinTimes == 2}
                        onChange={(event) => setSpinTimes(event.target.value)}
                        label={`3 Spin: 2x of the bet amount`}
                      />
                      </span>
                      <OverlayTrigger placement="top" overlay={tooltipthird}>
                        <Button bsStyle="default">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </Form>
                </div>
                <Button
                  type="button"
                  variant="unset"
                  className="mt-3"
                  onClick={makeTransaction}
                >
                  Bet
                </Button>
              </div>
              {/* } */}
            </Col>
            <Col md={6}>
              <SpinWheel
                spinTimes={spinTimes}
                betNumber={betNumber}
                setBetNumber={setBetNumber}
                tmpArray={tmpArray}
                spinChance={0}
                canSpinNow={canSpinNow}
                setcanSpinNow={setcanSpinNow}
                setspinNumber={setspinNumber}
                spinNumber={spinNumber}
                setdisableAreaLeft={setdisableAreaLeft}
                winningAmount={winningAmount}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* place-bet-steps-popup */}
      <Modal
        show={showPlaceBetSteps}
        backdrop="static"
        onHide={handleClosePlaceBetSteps}
        centered
        className="connect-wallet-box follow-steps-popup"
      >
        <Modal.Body>
          <h3 className="mt-0">Follow Steps </h3>
          <div className="step-area">
            <div className="steps-left-area">
              {tokenApproved ? <h2>1</h2> : <div class="loader"></div>}
            </div>

            <div className="steps-content">
              <h6>Approving USDC</h6>
              <p>Approving USDC</p>
            </div>
          </div>
          <div className="step-area">
            <div className="steps-left-area">
              {tokenApproved ? <div class="loader"></div> : <h2>2</h2>}
            </div>

            <div className="steps-content">
              <h6>Wheel Spin Transaction</h6>
              <p>Send transaction to spin USDC</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Spinner;

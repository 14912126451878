import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const JumboGame = () => {
    return (
        <>
          <section className="prediction-area">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <div className="prediction-area-left">
                                <img src={require("../../assets/images/prediction-vector.png")} alt="img" />
                                <h2 className="russo-one">Spin to Win Big </h2>
                                <p>Experience the thrill of spinning the Jumbo Slot Machine and winning big prizes! Our Jumbo Slot Machine offers exciting gameplay with multiple tries and lucrative payout opportunities. Simply select your preferred number of tries and enter your bet amount to get started. With each spin, you could multiply your winnings and walk away with substantial rewards.</p>
                                <button className="disable-btn">
                                    Coming Soon
                                    <div className="star-1 ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="star-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="star-3">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="star-4">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="star-5">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="star-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            version="1.1"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                imageRendering: "optimizeQuality",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd"
                                            }}
                                            viewBox="0 0 784.11 815.53"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <defs />
                                            <g id="Layer_x0020_1">
                                                <metadata id="CorelCorpID_0Corel-Layer" />
                                                <path
                                                    className="fil0"
                                                    d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="prediction-area-right"  data-aos="fade-left" data-aos-duration="2000">
                                <img src={require("../../assets/images/jumbo.png")} alt="img" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section></>
    );
};
export default JumboGame;
import { Col, Container, Row, Tab, Table, Tabs } from "react-bootstrap";
import { useState, useEffect } from "react";
import moment from "moment";
import { apiService } from "../service/api.service";
import { formatAddress, formatBalance } from "../common/connectWallet";
import Pagination from "react-js-pagination";
import Loader from "../common/Loader";
import { consttransactionurl } from "../config/config";

const TransactionManagement = () => {
    const [loader, setLoader] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [type, setType] = useState("");

    useEffect(() => {
        userTransaction(page, "");
    }, []);

    async function userTransaction(page, type) {
        setLoader(true);
        try {
            const response = await apiService.userTransaction(page, type);
            if (response.status === 200) {
                const { predictionDetail, totalRecords } = response.data.data;
                setTransactions(predictionDetail);
                setTotalCount(totalRecords);
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        } finally {
            setLoader(false);
        }
    }

    function handlePageChange(pageNumber) {
        if (page !== pageNumber - 1) {
            setPage(pageNumber - 1);
            userTransaction(pageNumber - 1, type);
        }
    }

    function handleSelect(key) {
        if (key === "prediction" || type !== key) {
            setType(key);
            setPage(0);
            userTransaction(0, key);
        }
    }

    return (
        <div className="transaction-outer">
            <section className="transaction-management-area">
                {loader && <Loader />}
                <Container>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12} lg={12}>
                                <div className="heading-top-area">
                                    <div className="d-flex">
                                        <h2 className="align-self-center m-0">Transaction Management</h2>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="transaction-tab">
                            <Tabs
                                defaultActiveKey="prediction"
                                id="uncontrolled-tab-example"
                                className=""
                                onSelect={handleSelect}
                            >
                                <Tab eventKey="prediction" title="Prediction Market">
                                    <Row className=" justify-content-center">
                                        <Col md={12} lg={12}>
                                            <div className="audit-request-box">
                                                <Table responsive="xxl" className="UserListTable">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Prediction Name</th>
                                                            <th>Amount</th>
                                                            <th>Reward Amount</th>
                                                            <th>Hash</th>
                                                            <th>Answer</th>
                                                            <th>Result</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactions.length > 0 ? (
                                                            transactions.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        <a href={"/prediction/" + data.prediction_id?.contract_address} target="_blank">{data.prediction_id?.question}</a>
                                                                    </td>
                                                                    <td>{formatBalance(data.amount)}</td>
                                                                    <td>{data.reward_amount !== 0 ? parseFloat(formatBalance(data.reward_amount - data.amount)) : 0}</td>
                                                                    <td>
                                                                        <a target="_blank" href={consttransactionurl + data.transaction_hash}>{formatAddress(data.transaction_hash)}</a>
                                                                    </td>
                                                                    <td>{data.prediction?.toString()}</td>
                                                                    <td>{data.prediction_id?.result === null ? "Not Updated" : data.prediction_id?.result.toString()}</td>
                                                                    <td>{moment(data?.date).format("yyyy-MM-DD, hh:mm A")}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="8">
                                                                    <div className="no-data-area main-no-data table-no-data">
                                                                        <img src={require("../assets/images/no-data.svg").default} alt="img" />
                                                                        <p>No Data Found</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                {transactions.length > 0 && (
                                                    <Pagination
                                                        activePage={page + 1}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={totalCount}
                                                        pageRangeDisplayed={5}
                                                        onChange={(e) => handlePageChange(e)}
                                                        prevPageText={"Prev"}
                                                        nextPageText={"Next"}
                                                    />
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="spin" title="Spin The Wheel">
                                    <Row className="justify-content-center">
                                        <Col md={12} lg={12}>
                                            <div className="audit-request-box">
                                                <Table responsive="xxl" className="UserListTable">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Wallet Address</th>
                                                            <th>Amount</th>
                                                            <th>Predicted Number</th>
                                                            <th>Hash</th>
                                                            <th>Spin Chances</th>
                                                            <th>Reward</th>
                                                            <th>Result</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactions.length > 0 && type === "spin" ? (
                                                            transactions.map((data, index) => (
                                                                <>
                                                                {console.log("data",data.transaction_hash, data)}
                                                                
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{formatAddress (data.user)}</td>
                                                                    <td>{formatBalance(data.amount)}</td>
                                                                    <td>{data.predictedNumber}</td>
                                                                    <td>
                                                                        <a target="_blank" href={consttransactionurl + data.transaction_hash}>{formatAddress(data.transaction_hash)}</a>
                                                                    </td>
                                                                    <td>{data.spinChances + 1}</td>
                                                                    <td>{data.reward !== 0 ? formatBalance(data.reward) : 0}</td>
                                                                    <td>{data.isWin ? "Won" : "Lost"}</td>
                                                                    <td>{moment(data?.date).format("yyyy-MM-DD, hh:mm A")}</td>
                                                                </tr>
                                                                
                                                                </>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="8">
                                                                    <div className="no-data-area main-no-data table-no-data">
                                                                        <img src={require("../assets/images/no-data.svg").default} alt="img" />
                                                                        <p>No Data Found</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                {transactions.length > 0 && (
                                                    <Pagination
                                                        activePage={page + 1}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={totalCount}
                                                        pageRangeDisplayed={5}
                                                        onChange={(e) => handlePageChange(e)}
                                                        prevPageText={"Prev"}
                                                        nextPageText={"Next"}
                                                    />
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="jumbo" title="Jumbo Slot Machine">
                                    <Row className=" justify-content-center">
                                        <Col md={12} lg={12}>
                                            <div className="audit-request-box">
                                                <Table responsive="xxl" className="UserListTable">
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="8">
                                                                <div className="no-data-area main-no-data table-no-data">
                                                                    <img src={require("../assets/images/no-data.svg").default} alt="img" />
                                                                    <p>Coming Soon</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default TransactionManagement;

import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useState, useEffect, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import FormRange from "react-bootstrap/FormRange";
import { ShimmerButton, ShimmerCircularImage, ShimmerThumbnail } from "react-shimmer-effects";
import Slider from "@mui/material/Slider";
import { PieChart } from "@mui/x-charts/PieChart";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import {
  Handleconnect,
  formatChainAsNum,
  formatAddress,
  formatBalance,
  getEstimateGasFee,
} from "../common/connectWallet";
import { ethers } from "ethers";
import { contractAbi } from "../config/contractAbi";
import { tokenAbi } from "../config/tokenAbi";
import {
  setWalletAddress,
  setwalletConnected,
  setwalletBalance,
} from "../store/index_slice";
import { useSelector, useDispatch } from "react-redux";
import NotConnectWalletPopup from "../common/NotConnectWalletPopup";
import moment from "moment";
import Countdown from "react-countdown";
import { checkToken } from "../common/CommonComponent";

import Spinner from "./Spinner";
import ResponsiveCarousel from "./Slider";

import Loader from "../common/Loader";
import {
  chainId,
  tokenAddress,
  baseUrl,
  gasFeePercent,
  divideBy,
  decimalTo,
} from "../config/config";

import { apiService } from "../service/api.service";
import swal from "sweetalert";
import { Link, useParams } from "react-router-dom";
const { Web3 } = require("web3");


const Prediction = ({ showpop, setShowpop, predictions, setpredictions, address, sliderIndex }) => {
  console.log("tushar predictions >>>>>", predictions);
  const [betAmount, setBetAmount] = useState(4);
  const swiperRef = useRef(null);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false)
  const [truePer, settruePer] = useState();
  const [answer, setanswer] = useState(true);
  const [show, setShow] = useState(false);
  const [falsePer, setfalsPer] = useState();
  const autoSelectedValue = 5;
  const [predictionCount, setpredictionCount] = useState();
  const [showPlaceBetSteps, setShowPlaceBetSteps] = useState(false);
  const [madebid, setmadebid] = useState(false);
  const [bidbtn, setbidbtn] = useState(false);
  const [contractAddress, setcontractAddress] = useState();
  const [leaderboard, setleaderboard] = useState()
  const [limit, setlimit] = useState(10)
  const [is_walletConnected, setis_walletConnected] = useState(
    localStorage.getItem("is_walletConnected")
  );
  const [Isblur, setblur] = useState(false)
  const [showLeaderBoard, setshowLeaderBoard] = useState(true)
  const iswallet = useSelector((state) => state.reducer.walletConnected);
  const [price, setPrice] = useState(1);
  const [payoutprediction, setpayoutprediction] = useState(0);
  const [tokenApproved, settokenApproved] = useState(false);
  const [currentPredictionIndex, setCurrentPredictionIndex] = useState(0);
  const [showleaderboardData, setShowleaderboardData] = useState(true);
  const handleClosePop = () => {
    window.history.pushState(null, '', "/");
    setShowpop(false);

  }
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  
  useEffect(() => {

    // updateCurrentPrediction();
    // console.log("tushar useffect sliderIndex", sliderIndex);
    //  setTimeout(setLoader(false), 5000);
    if (predictions[sliderIndex]) {
      leaderboardDetail(predictions[sliderIndex].contract_address, limit)
    }

    goToSlide(sliderIndex)
  }, [sliderIndex]);
  // const leaderboardData = (param) => {
  //   setshowLeaderBoard(param)
  // }
  const goToSlide = (i) => {
    if (window.location.pathname.split("/")[1] == "prediction") {
      window.history.pushState({ address }, '', `${address}`);
    } else {
      window.history.pushState({ address }, '', `prediction/${address}`);
    }
    // leaderboardDetail(predictions[i].contract_address, limit)
    const index = i;
    if (index !== undefined && swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };
  const leaderboardData = (param) => {
    setShowleaderboardData((prevShowDiv1) => !prevShowDiv1);
    setshowLeaderBoard(param)
  };
  
  const updateCurrentPrediction = async () => {
    try {
      // leaderboardDetail(predictions[currentPredictionIndex].contract_address, limit);
      console.log("updateCurrentPrediction currentPredictionIndex", currentPredictionIndex);
      const response = await apiService.get_predictionDetail(predictions[currentPredictionIndex].contract_address);
      if (response.status == 200) {
        console.log("updateCurrentPrediction ====current prediction detail ===", response.data.data);
        // predictions[currentPredictionIndex] 
        let temp = [...predictions];
        temp[currentPredictionIndex] = response.data.data;
        setpredictions(temp);
        leaderboardDetail(predictions[currentPredictionIndex].contract_address, limit);

      }
    } catch (error) {
      //   setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  }

  const handleClosePlaceBetSteps = () => {
    setShowPlaceBetSteps(false);
    setblur(false)
  }
  const handleShowPlaceBetSteps = () => {
    setblur(true)
    setShowPlaceBetSteps(true);
  }

  function payoutpredict(
    amtPredicted,
    _totalAmtYesPredictedWei,
    _totalAmtNoPredictedWei,
    _userSelected
  ) {
    console.log("isPredictNo>>>>>>>>>>>>>>", _userSelected);
    let _totalAmtRightPredictedWei, _totalAmtWrongPredictedWei;
    if (_userSelected) {
      _totalAmtRightPredictedWei = _totalAmtYesPredictedWei;
      _totalAmtWrongPredictedWei = _totalAmtNoPredictedWei;
    } else {
      _totalAmtRightPredictedWei = _totalAmtNoPredictedWei;
      _totalAmtWrongPredictedWei = _totalAmtYesPredictedWei;
    }


    let amtPredictedWei = amtPredicted * divideBy;
    if (_totalAmtRightPredictedWei === 0) {
      console.log("tushar if");
      let payoutWei = amtPredictedWei + _totalAmtWrongPredictedWei;
      return parseFloat(formatBalance(payoutWei));
      // setpayoutprediction(parseFloat(formatBalance(payoutWei)))
    } else {
      let payoutWei =
        amtPredictedWei +
        (amtPredictedWei / (_totalAmtRightPredictedWei + amtPredictedWei)) *
        _totalAmtWrongPredictedWei;
      return parseFloat(formatBalance(payoutWei));
      // setpayoutprediction(parseFloat(formatBalance(payoutWei)))
    }
  }


  function payoutpredictOld(
    amtPredicted,
    _totalAmtRightPredictedWei,
    _totalAmtWrongPredictedWei,
    isPredictNo
  ) {
    console.log("isPredictNo>>>>>>>>>>>>>>", isPredictNo);
    let amtPredictedWei = amtPredicted * divideBy;
    if (_totalAmtRightPredictedWei === 0) {
      console.log("tushar if");
      let payoutWei = amtPredictedWei + _totalAmtWrongPredictedWei;
      return parseFloat(formatBalance(payoutWei));
      // setpayoutprediction(parseFloat(formatBalance(payoutWei)))
    } else {
      let payoutWei =
        amtPredictedWei +
        (amtPredictedWei / (_totalAmtRightPredictedWei + amtPredictedWei)) *
        _totalAmtWrongPredictedWei;
      return parseFloat(formatBalance(payoutWei));
      // setpayoutprediction(parseFloat(formatBalance(payoutWei)))
    }
  }


  async function pricePrediction(pp) {
    console.log("=====", pp);
    setPrice(pp);
    var commission = (1 / 100) * pp;

    // if (answer == true) {

    // payoutpridict(pp, predictionCount.totalTrueAmount, predictionCount.totalFalseAmount)
    // } else {
    // console.log(pp, predictionCount.totalFalseAmount, predictionCount.totalTrueAmount)
    // payoutpridict(pp, predictionCount.totalFalseAmount, predictionCount.totalTrueAmount)
    // }
    // setplatforFee(commission.toFixed(2))
  }

  function valuetext(value, index) {
    console.log("bbbbbb valuetext", value);
    let temp = [...predictions];
    temp[index].amountSelected = value;

    setpredictions(temp);
  }

  function predictOptionSel(predictYes, index) {
    // console.log("predictYes", predictYes, !predictYes);
    let temp = [...predictions];
    setanswer(predictYes);
    temp[index].predictNo = !predictYes;

    setpredictions(temp);
  }
  const [value, setValue] = useState(0);
  const numSteps = 5; // Number of steps including 0 and 5

  const handleChange = (event) => {
    setValue(parseInt(event.target.value));
  };

  const stepWidth = 100 / (numSteps - 1);

  function DiscreteSlider({ index }) {
    return (


      <div style={{ width: '100%', maxWidth: '100%' }}> {/* Adjust max width as needed */}

        {/* <Form.Label>Range</Form.Label> */}
        <Form.Range
          min={1}
          max={5}
          onChange={(event) => valuetext(event.target.value, index)}
          value={
            predictions[index]?.amountSelected
              ? predictions[index]?.amountSelected
              : autoSelectedValue
          }
          list="markers"
        />
        <div className="rangebar-values">
          {[...Array(numSteps).keys()].map((step) => (

            <span key={step}>{step + 1}</span>
          ))}
        </div>

      </div>
    );
  }
  const handleNextSlide = (e) => {
    console.log("Next slide reached!", e.activeIndex);
    if (window.location.pathname.split("/")[1] == "prediction") {
      window.history.pushState({ address }, '', `${predictions[e.activeIndex].contract_address}`);
    } else {
      window.history.pushState({ address }, '', `prediction/${predictions[e.activeIndex].contract_address}`);
    }
    leaderboardDetail(predictions[e.activeIndex].contract_address, limit)

    setCurrentPredictionIndex(e.activeIndex);
    setanswer(true);
    // Add your custom logic here
  };


  const connectWallet = async () => {
    if (!iswallet) {
      let accounts = await Handleconnect();
      if (accounts == "meta_error") {
        handleClose();
      } else {
        if (accounts) {
          dispatch(setwalletConnected(true));
          dispatch(setWalletAddress(accounts));
          setis_walletConnected(true);
          handleClose();
          makeBid(accounts[0], predictions[currentPredictionIndex].contract_address, price);
          return true;
        } else {
          return true;
        }
      }
    }
  };

  const approveToken = async (
    toContract,
    tokenammount,
    price,
    predictionContract,
    walletAddress,
    address
  ) => {
    setbidbtn(true);
    settokenApproved(false);
    let transactionHash;
    try {
      let wallet = localStorage.getItem("connected_wallet");
      if (!wallet) {
        wallet = walletAddress;
      }

      handleShowPlaceBetSteps();
      let sendParams = { from: wallet };

      try {
        // tokenammount.toString()
        const web3 = new Web3(window.ethereum);
        let gasPriceGet = await web3.eth.getGasPrice();
        let gasLimit = await toContract.methods
          .approve(address, tokenammount.toString())
          .estimateGas(sendParams)
          .then((res) => {
            return res;
          });
        sendParams.gasPrice = Math.ceil(Number(gasPriceGet) * gasFeePercent);
        sendParams.gas = Math.ceil(Number(gasLimit) * gasFeePercent);
      } catch (error) { }

      await toContract.methods
        .approve(address, "100000000")
        .send(sendParams)
        .on("transactionHash", function (hash) {
          transactionHash = hash;

          // setmessage2("Waiting for receipt.");
        })
        .on("receipt", async function (tx) {
          // console.log("=======receipt===")
          settokenApproved(true);
          // console.log("settokenApproved to true");
          // console.log("settokenApproved to true",predictionContract, price, walletAddress);
          await makePrediction(predictionContract, price, walletAddress);
        })
        .on("confirmation", async function (conf) {
          //  console.log("=======confirmation===",conf)
          // setmessage2("Item Bought Successfully!!!");
          // getdetail(ContractRefcollection.current);
        })
        .on("error", async function (error) {
          handleClosePlaceBetSteps();
          console.log("error  =====->", error.code);
          if (error.code == 432) {
            let validTransaction = checkTransaction(transactionHash);
            if (validTransaction) {
              await makePrediction(predictionContract, price, walletAddress);
            }
          }
          // console.log("=======error===")
        });
    } catch (error) {
      console.error("error  =========->", error.code);
      console.error("error  =========->", error.toString());
      if (error.code == 432) {
        let validTransaction = checkTransaction(transactionHash);
        if (validTransaction) {
          await makePrediction(predictionContract, price, walletAddress);
        }
      } else {
        handleClosePlaceBetSteps();
        setbidbtn(false);
        let err = error.toString();
        if (err.includes("User denied transaction signature.")) {
          swal("Error", "User denied transaction signature.", "error");
        }
      }
    }
  };

  const checkTransaction = async (transactionHash) => {
    // console.log("====transactionHash===",transactionHash)
    const web3 = new Web3(window.ethereum);
    web3.eth
      .getTransactionReceipt(transactionHash)
      .then((receipt) => {
        if (receipt) {
          // console.log("====receipt===",receipt)
          // Check if transaction status is successful
          if (receipt.status === true || receipt.status === "0x1") {
            return true;
          } else {
            return false;
          }
        } else {
          console.log(
            "Transaction receipt not found. It may still be pending."
          );
        }
      })
      .catch((error) => {
        console.error("Error getting transaction receipt:", error);
        // Handle the error
      });
  };
  const checkWalletConnect = async (address) => {
    let is_walletConnecte = localStorage.getItem("is_walletConnected");
    console.log("====is_walletConnecte===", is_walletConnecte)
    if (is_walletConnecte) {
      makeBid(localStorage.getItem("connected_wallet"), address, price);
    } else {
      handleShow();
    }
  };
  const getBalance = async () => {
    const webb3 = new Web3(window.ethereum);
    let toContract = new webb3.eth.Contract(tokenAbi, tokenAddress);

    let userBalance = await toContract.methods
      .balanceOf(localStorage.getItem("connected_wallet").toString())
      .call();

    let balance = formatBalance(userBalance.toString());

    dispatch(setwalletBalance(balance));
  };
  const checkConnection = () => {
    const webb3 = new Web3(window.ethereum);
    let walletConnected = true;
    return webb3.eth.getAccounts().then(async (addr) => {
      if (addr.length == 0) {
        localStorage.clear();
        handleShow();
        walletConnected = false;
      } else {
        // console.log(addr[0],"!==",localStorage.getItem("connected_wallet"))
        if (
          addr[0].toLowerCase() !==
          localStorage.getItem("connected_wallet").toLowerCase()
        ) {
          setmadebid(false);
          setbidbtn(false);
          swal(
            "Warning",
            "You've linked to a different wallet address. Kindly verify in MetaMask.",
            "warning"
          );
          walletConnected = false;
        } else {
          walletConnected = true;
        }
      }
      // console.log(walletConnected)
      return walletConnected;
    });
  };
  const makeBid = async (walletAddress, address, price) => {
    let checkconnection = await checkConnection();
    if (checkconnection) {
      let data = await checkprediction(address, walletAddress);

      try {
        const webb3 = new Web3(window.ethereum);

        let CurrentchainId = await formatChainAsNum();

        if (CurrentchainId != chainId) {
          await switchNetwork();
        }
        let toContract = new webb3.eth.Contract(tokenAbi, tokenAddress);

        let userBalance = await toContract.methods
          .balanceOf(walletAddress.toString())
          .call();
        // let walletconnect = await  connectWallet()
        let balance = formatBalance(userBalance.toString());
        console.log("=======price=====", price);
        if ((!price || parseInt(price) == 0) && walletAddress) {
          setbidbtn(false);
          return;
        }
        if (price) {
          console.log("tushar 1");

          var commission = (1 / 100) * price;
          var totaltoken = parseFloat(price) + parseFloat(commission);
          totaltoken = totaltoken.toFixed(3);

          // console.log(balance + "<" + totaltoken, "====totaltoken===");
          if (parseFloat(balance) < parseFloat(totaltoken)) {
            swal("Error", "You don't have sufficient balance.", "error");
            setbidbtn(false);
            return;
          }
          let predictionContract = new webb3.eth.Contract(contractAbi, address);
          let tokenammount = ethers.utils.parseUnits(
            totaltoken.toString(),
            decimalTo
          );
          let tokenprice = ethers.utils.parseUnits(price.toString(), decimalTo);
          let approvedToken = await checkToken("MATIC", "USDC6", address)


          if (parseInt(tokenammount) > parseInt(approvedToken)) {
            // return
            await approveToken(
              toContract,
              tokenammount,
              tokenprice,
              predictionContract,
              walletAddress,
              address
            );
          } else {
            handleShowPlaceBetSteps()
            setTimeout(function () {
              settokenApproved(true)
            }, 2000);
            await makePrediction(predictionContract, tokenprice, walletAddress);
          }
        }
      } catch (error) {
        console.log(error, "=====error");
      }
    } else {
      await connectWallet()
    }
  };
  async function checkprediction(address, walletAddress) {
    try {
      const response = await apiService.checkprediction(address, walletAddress);
      if (response.status == 200) {
        console.log("=====bet===", response.data.data);
        return response.data.data;
      }
    } catch (error) {
      //   setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  }
  const getpercentage = (truevalue, falsevalue) => {
    let totalval = truevalue + falsevalue;
    let truepercentage = (truevalue / totalval) * 100;
    console.log("===truepercentage===", truepercentage);
    let falsepercentage = (falsevalue / totalval) * 100;
    settruePer(truepercentage);
    setfalsPer(falsepercentage);
  };

  const makePrediction = async (
    predictionContract,
    amt,
    walletAddressconnected
  ) => {
    try {

      console.log("makePrediction =====amt", amt);
      // console.log(amt / 1000000000000000000)

      // let finalamt = (amt / 1000000000000000000).toString()
      let walletAddress = localStorage.getItem("connected_wallet");
      if (!walletAddress) {
        walletAddress = walletAddressconnected;
      }


      let sendParams = { from: walletAddress };
      const web3 = new Web3(window.ethereum);
      try {

        let gasPriceGet = await web3.eth.getGasPrice();
        let gasLimit = await predictionContract.methods
          .bet(answer, amt.toString())
          .estimateGas(sendParams)
          .then((res) => {
            return res;
          });

        sendParams.gasPrice = Math.ceil(Number(gasPriceGet) * gasFeePercent);
        sendParams.gas = Math.ceil(Number(gasLimit) * gasFeePercent);

      } catch (error) {
        console.log("error makePrediction calculating gas ", error);
      }


      try {
        await predictionContract.methods.bet(answer, amt.toString()).send(sendParams)

        setbidbtn(false);
        handleClosePlaceBetSteps();
        swal("Success", "Prediction placed successfully.", "success");
        getBalance();
        console.log("predictionContract", predictionContract)
        // leaderboardDetail(predictions[currentPredictionIndex].contract_address, limit);
        updateCurrentPrediction();

      } catch (error) {
        console.log("error in bet >>>>>", error);

        setbidbtn(false);
        setmadebid(false);
        setbidbtn(false);
        if (error.code === 4001) {
          swal("Error", "User denied transaction signature.", "error");
        } else {
          swal("Error", "something went wrong.", "error");
        }

        handleClosePlaceBetSteps();
      }

    } catch (error) {
      console.log("error >> catch", error);
      setmadebid(false);
      setbidbtn(false);
      let err = error.toString();
      if (err.includes("User denied transaction signature.")) {
        swal("Error", "User denied transaction signature.", "error");
      }
      handleClosePlaceBetSteps();
    }
  };
  async function leaderboardDetail(address, lmt) {
    try {
      const response = await apiService.leaderboardDetail(address, lmt);
      if (response.status == 200) {
        setleaderboard(response.data.data)
      }
    } catch (error) {
      //   setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  }
  async function switchNetwork() {
    try {
      let chain = ethers.utils.hexValue(chainId);
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain }], // chainId must be in hexadecimal numbers
      });
      return true;
    } catch (switchError) {
      // The network has not been added to MetaMask
      if (switchError.code === 4902) {
        swal("error", "Please add the Polygon network to MetaMask", "error");
      }
      return false;
      console.log("Cannot switch to the network");
    }
  }
  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    // console.log(days, hours, minutes, seconds, completed)
    if (completed) {
      // console.log("===completed")
      console.log("===completed", currentPredictionIndex)
      predictions[currentPredictionIndex].closed = true
      return <span className="time-closed">Closed</span>;

      // setmadebid(true);

    } else {
      // console.log("===not completed")
      predictions[currentPredictionIndex].closed = false
      return (
        <span>
          <div className='countdown-area-top'>
            <div className='countdown-inner-content'>
              <p>{days}</p>
              <h3>days</h3>
            </div>

            <div className='countdown-inner-content'>
              <p>{hours}</p> <h3>hours</h3>
            </div>
            <div className='countdown-inner-content'>
              <p>{minutes}</p> <h3>minutes</h3>
            </div>
            <div className='countdown-inner-content'>
              <p>{seconds} </p><h3>seconds</h3>
            </div>



          </div>
          {/* {days + "d"} {hours + "h"}:{minutes + "m"}:{seconds + "s"} */}
          {/* {days>0?
                <>{days + "d"} {hours + "h"}:{minutes + "m"}:{seconds + "s"}</>:
                <>  {hours>0? <>{hours + "h"}:{minutes + "m"}:{seconds + "s"}</>:
                  <>{minutes + "m"}:{seconds + "s"}</>}
                
                </>} */}
        </span>
      );
    }
  };
  const sizing = {
    margin: { right: 5 },
    width: 200,
    height: 200,
    legend: { hidden: true },
  };
  return (
    <>

      {predictions && (
        <Modal backdrop="static" show={showpop} onHide={handleClosePop} centered size="xl" className={Isblur ? "isBlur" : "prediction-game-popup"}>
          {loader && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Prediction</Modal.Title>
          </Modal.Header>


          {predictions && predictions[currentPredictionIndex]?.result != null && predictions[currentPredictionIndex].userAmount > 0 &&
            <div className="top-result">
              <Row className="congrats-popup-area">

                <Col md={12} className="p-0">
                  {predictions[currentPredictionIndex].result == predictions[currentPredictionIndex].userOutcome ?

                    <div className="congrats-popup-area">
                      <img src={require("../assets/images/congrats.png")} alt="congrats" />
                      <p>Your prediction scored a win, rewarding you with <span>
                        {parseFloat(formatBalance(predictions[currentPredictionIndex].rewardAmount))}
                        USDC.</span></p>
                    </div>




                    :
                    <>

                      <div className="prediction-lost-area">

                        <div className="prediction-lost-content">
                          <img src={require("../assets/images/lose.png")} alt="congrats" />
                          <p>Don't give up! Your winning streak is just a prediction away.</p>
                        </div>
                      </div>

                    </>



                  }
                </Col>
              </Row>
            </div>
          }


          <Modal.Body>
            <Swiper
              ref={swiperRef}
              cssMode={true}
              navigation={true}
              pagination={true}
              mousewheel={true}
              keyboard={true}
              modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              className="mySwiper"
              onSlideChange={(e) => handleNextSlide(e)}
            >
              {predictions && predictions?.length > 0 ? (
                predictions.map((data, index) => {

                  // console.log("====mapdata==", data);

                  // if(data.userAmount>0){
                  //   predictions[index].closed = true
                  // }
                  // console.log("====yyyyyyy==", moment(data.endDateTime));
                  // var time = moment.duration("00:03:15");
                  let betTime = moment(predictions[currentPredictionIndex].endDateTime).subtract(
                    data.threshold_time,
                    "minutes"
                  );


                  return (
                    <SwiperSlide key={data.id} data-id={data.contract_address}>
                      <Row className="align-items-center slider-area-popup">
                        <Col md={6}>
                          <div className="prediction-swiper-left ">
                            
                            {showleaderboardData ? (
                              <div className="piechart-front-area">


                                {betTime ? (
                                  <Countdown
                                    date={moment(betTime).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                    renderer={renderer}
                                  >
                                    {" "}
                                  </Countdown>
                                ) : null}
                                {/* <CountDown
                                targetDate={Date.parse('04 Dec 2024 00:12:00 GMT')}
                             /> */}
                                <h5>Forecast</h5>
                                <div className="pie-chart-area">
                                  {console.log("data>>>>>>>>>>>>mmmm", data)}
                                  {data.countsAndAmounts.trueData == 0 && data.countsAndAmounts.falseData == 0 ?

                                    <div className="no-prediction-img">
                                      <img src={require("../assets/images/no-prediction.png")} alt="lost" />
                                    </div> :

                                    <PieChart
                                      colors={["#FBB936", "#ED2771"]}
                                      series={[
                                        {
                                          data: [
                                            {
                                              id: 0,
                                              value: formatBalance(data.countsAndAmounts.falseAmount)

                                            },
                                            {
                                              id: 1,
                                              value: formatBalance(data.countsAndAmounts.trueAmount)

                                            },
                                          ],

                                        },
                                      ]}
                                      {...sizing}
                                    />}
                                </div>

                                <div className="piechart-diff">
                                  <p className="true">
                                    <span>Yes</span> (
                                    {formatBalance(
                                      data.countsAndAmounts.trueAmount
                                    )}{" "}
                                    USDC {data.countsAndAmounts.trueData} {data.countsAndAmounts.trueData == 1 ? "bet" : "bets"})
                                  </p>
                                  <p className="false">
                                    <span>No</span> (
                                    {formatBalance(
                                      data.countsAndAmounts.falseAmount
                                    )}{" "}
                                    USDC {data.countsAndAmounts.falseData} {data.countsAndAmounts.falseData == 1 ? "bet" : "bets"})
                                  </p>
                                </div>

                                <h6>
                                  <b>Total:</b>{" "}
                                  <span>
                                    {formatBalance(
                                      data.countsAndAmounts.totalAmount
                                    )}{" "}
                                    USDC
                                  </span>{" "}
                                  ({data.countsAndAmounts.totalCountData} {data.countsAndAmounts.totalCountData == 1 ? "bet" : "bets"})
                                </h6>
                              </div>
                            ) : (
                              <div className="leader-board-content">
                                <div className="leader-board-top">
                                  <h5>Prediction Leader-board </h5>
                                  {leaderboard?.leaderboardTotalCount > 10 &&
                                    <a href="#" onClick={() => leaderboardDetail(data.contract_address, parseInt(limit + 10))}>View More</a>
                                  }
                                </div>
                                <div className="leader-board-outer-area">
                                  {leaderboard?.leaderboard && leaderboard.leaderboard?.length > 0 && !showLeaderBoard ? (
                                    leaderboard.leaderboard.map((data, index) => {
                                      return (
                                        <div className="leader-botton-content-mid">
                                          <p>{formatAddress(data.user)}</p>

                                          <h4>{formatBalance(data.totalAmount)} USDC <span className="prediction-board-ans">({(data.prediction ? "Yes" : "No").toString()})</span></h4>
                                        </div>

                                      )
                                    })
                                  ) : (
                                    <>
                                      {leaderboard?.leaderboard && leaderboard.leaderboard?.length == 0 &&
                                        <div className="leader-board-content">
                                          <div className="no-data-area">
                                            <img src={require("../assets/images/no-data.svg").default} alt="img" />
                                            <p> No Data Found</p>
                                          </div>
                                        </div>
                                      }
                                    </>
                                  )}
                                </div>
                              </div>
                            )}

                            {leaderboard && leaderboard.leaderboard?.length > 0 && showLeaderBoard &&
                              <Button type="button" variant="unset" onClick={() => leaderboardData(false)}>
                                View Leader-board
                              </Button>}
                            {!leaderboard &&
                              <h2>
                                <ShimmerButton size="lg" />
                              </h2>

                            }
                            {!showLeaderBoard && leaderboard.leaderboard?.length > 0 &&
                              <Button type="button" variant="unset" onClick={() => leaderboardData(true)}>
                              <img src={require("../assets/images/btn-back-arrow.svg") .default} alt="img" /> Back to Forecast
                              </Button>}

                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="prediction-right-area predict-popup-game">
                            <h5> {data.question}</h5>
                            <div className="outer-spacing-area">
                              <div className="prediction-option">
                                <p
                                  className={
                                    data.predictNo
                                      ? "optn-yes"
                                      : "optn-yes active"
                                  }
                                  onClick={() => {
                                    predictOptionSel(true, index);
                                  }}
                                >
                                  YES
                                </p>
                                <h6>or</h6>
                                <p
                                  className={
                                    data.predictNo
                                      ? "optn-no active"
                                      : "optn-no"
                                  }
                                  onClick={() => {
                                    predictOptionSel(false, index);
                                  }}
                                >
                                  NO
                                </p>
                              </div>
                              <div className="range-area">
                                <h4>How much you bet on it?</h4>
                                {/* <CustomMarks
                                 setValue={setBetAmount} value={betAmount} 
                                /> */}

                                <DiscreteSlider index={index} />

                                {/* <Box sx={{ width: "100%" }}>
                                                        <Slider 
                                                    
                                                        defaultValue={10} step={5} marks min={1} max={100} value={price} aria-label="USDC" valueLabelDisplay="auto" getAriaValueText={valuetext}/>
                                                    </Box> */}
                              </div>
                              <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="1">
                                  {/* amtPredicted, _totalAmtRightPredictedWei, _totalAmtWrongPredictedWei */}
                                  <Accordion.Header>
                                    Win Amount:{" "}
                                    {payoutpredict(
                                      data.amountSelected
                                        ? data.amountSelected
                                        : autoSelectedValue,
                                      data.countsAndAmounts.trueAmount,
                                      data.countsAndAmounts.falseAmount,
                                      answer
                                    )}{" "}
                                    USDC
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    Payout is calculated based on other
                                    participants' bets and the current market's
                                    TVL (total value locked)
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                              <Button
                                type="button"
                                variant="unset"
                                className="place-bet-btn"
                                disabled={predictions[currentPredictionIndex].closed ? true : false}
                                onClick={(e) =>
                                  iswallet ||
                                    localStorage.getItem("is_walletConnected")
                                    ? (makeBid(
                                      localStorage.getItem(
                                        "connected_wallet"
                                      ),
                                      data.contract_address,
                                      data.amountSelected
                                        ? data.amountSelected
                                        : autoSelectedValue
                                    ),
                                      predictions[currentPredictionIndex].closed = true,
                                      setcontractAddress(data.contract_address),
                                      // setanswer(data.predictNo),
                                      setPrice(
                                        data.amountSelected
                                          ? data.amountSelected
                                          : autoSelectedValue
                                      ))
                                    : checkWalletConnect(data.contract_address)
                                }
                              >
                                Place Bet
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </SwiperSlide>
                  );
                })
              ) : (<>
                {!predictions ?
                  <Col md={12} lg={12} xl={12} className="mb-4">
                    <div className="no-data-area main-no-data">
                      <img src={require("../assets/images/no-data.svg").default} alt="img" />
                      <p> No Data Found</p>
                    </div>
                  </Col>
                  :
                  <Row className="align-items-center slider-area-popup">
                    <Col md={6}>
                      <div className="prediction-swiper-left ">
                        <h5><ShimmerButton size="md" /></h5>
                        <span className="time-closed shimmer-btn-closed">
                          <ShimmerButton size="md" />
                          <ShimmerButton size="md" />
                          <ShimmerButton size="md" />
                          <ShimmerButton size="md" />
                        </span>
                        <div className="pie-chart-area pie-shimmer">
                          <ShimmerCircularImage size={200} />;
                        </div>
                        <div className="piechart-diff">
                          <p className="true">
                            <ShimmerButton size="sm" />
                          </p>
                          <p className="false">
                            <ShimmerButton size="sm" />
                          </p>
                        </div>
                        <h6 className="total-bet">
                          <ShimmerButton size="sm" />
                        </h6>
                        {/* <div className="leader-board-top mt-4">
                          <h5><ShimmerButton size="sm" /></h5>
                      
                        </div> */}
                        {/* <div className="leader-botton-content-mid">
                      <p><ShimmerButton size="sm" /></p>
                      <h4><ShimmerButton size="sm" /> </h4>
                    </div>
                    <div className="leader-botton-content-mid">
                      <p><ShimmerButton size="sm" /></p>
                      <h4><ShimmerButton size="sm" /> </h4>
                    </div>
                    <div className="leader-botton-content-mid">
                      <p><ShimmerButton size="sm" /></p>
                      <h4><ShimmerButton size="sm" /> </h4>
                    </div> */}
                        <h2>
                          <ShimmerButton size="lg" />
                        </h2>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="prediction-right-area">
                        <h5><ShimmerButton size="md" /></h5>
                        <div className="prediction-option">
                          <p>
                            <ShimmerCircularImage size={60} />
                          </p>
                          <h6></h6>
                          <p>
                            <ShimmerCircularImage size={60} />
                          </p>
                        </div>
                        <div className="range-area">
                          <h4><ShimmerButton size="md" /></h4>
                          <p><ShimmerButton size="md" /></p>
                        </div>
                        <h6>  <ShimmerButton size="lg" /></h6>
                      </div>
                    </Col>
                  </Row>
                }
              </>
              )}
            </Swiper>
          </Modal.Body>


        </Modal>
      )}
      {/* place-bet-steps-popup */}
      <Modal
        show={showPlaceBetSteps}
        backdrop="static"
        onHide={handleClosePlaceBetSteps}
        centered
        className="connect-wallet-box follow-steps-popup"
      >

        <Modal.Body>
          <h3 className="mt-0">Follow Steps </h3>
          <div className="step-area">
            <div className="steps-left-area">
              {tokenApproved ? <h2>1</h2> : <div class="loader"></div>}
            </div>

            <div className="steps-content">
              <h6>Approving USDC</h6>
              <p>Approving USDC</p>
            </div>
          </div>
          <div className="step-area">
            <div className="steps-left-area">
              {tokenApproved ? <div class="loader"></div> : <h2>2</h2>}
            </div>

            <div className="steps-content">
              <h6>Predicting</h6>
              <p>Send transaction to predict USDC</p>
            </div>
          </div>
        </Modal.Body>

      </Modal>
      <NotConnectWalletPopup show={show} handleClose={handleClose} connectWallet={connectWallet} />
    </>
  );
};

export default Prediction;

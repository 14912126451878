import detectEthereumProvider from '@metamask/detect-provider'
import {useEffect,useState} from "react"
import swal from "sweetalert";

export const checkProvider = async() => {
    try{

    const provider = await detectEthereumProvider({ silent: true })

    return Boolean(provider)
    }    catch (error) {
        if(error=="ProviderError: Provider not available. Use `.setProvider` or `.provider=` to initialize the provider."){
            swal("warning","Please Install Metamask","warning")
          }
      }

}
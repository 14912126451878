import "./App.css";
import Routing from "./routing/Routing";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/Style.css"


function App() {
  return <Routing />;
}

export default App;

import axios from "axios";
import { apiUrl } from "../config/config";


export const apiService = {
    login,
  
    get_prediction,
    get_predictionDetail,
    leaderboardDetail,
    checkprediction,
    userTransaction,
    contactUs

 
}


const headers =() =>
    {
        let token = localStorage.getItem("token")


        return {headers : { 'Authorization': `${token}` }} 
    };

//    console.log('headers()....', headers());





async function login(data) {
    return await axios.post(`${apiUrl}/user/login/`, data);
}

async function get_prediction(limit,duration,type,prediction_contract_address){
  return await axios.get(`${apiUrl}/user/predictionList?limit=`+limit+"&duration="+duration+"&type="+type+"&prediction_contract_address="+prediction_contract_address, headers())
}
async function get_predictionDetail(address){
  return await axios.get(`${apiUrl}/user/predictionDetail/`+address, headers())
}
async function leaderboardDetail(address,limit){
  return await axios.get(`${apiUrl}/user/leaderboardDetail/`+address+'?limit='+limit, headers())
}

async function checkprediction(address,wallet_address){
  return await axios.get(`${apiUrl}/user/user-prediction/`+address+"/"+wallet_address)
}


async function userTransaction(page,type){
  return await axios.get(`${apiUrl}/user/user-transaction?page=`+page+"&type="+type,headers())
}



async function contactUs(data){
  return await axios.post(`${apiUrl}/user/contact-us`,data,headers())
}







  
 




















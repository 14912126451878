import { Button, Container, Dropdown, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { checkProvider } from "../common/checkProvider";
import { Handleconnect, formatAddress, formatBalance, formatChainAsNum } from "../common/connectWallet";
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom";
import { setWalletAddress, setwalletConnected ,setwalletBalance} from '../store/index_slice'
import { useSelector, useDispatch } from 'react-redux'
import ConnectWalletPopup from "../common/ConnectWalletPopup";
import swal from "sweetalert";

import { all } from "axios";
import { tokenAbi } from "../config/tokenAbi";
import { tokenAddress } from "../config/config";
const { Web3 } = require('web3');
const Header = () => {
  const walletAddress = useSelector((state) => state.reducer.walletAddress)
  const walletBalance = useSelector((state) => state.reducer.walletBalance)
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []); 
  const [showconnect, setShowconnect] = useState(false)
  const handleCloseConnect = () => {
    setShowconnect(false)
    window.location.reload()
  }
  const handleShowConnect = () => setShowconnect(true);
  const location = useLocation()
  useEffect(() => {
    // checkConnection()
    // setHasProvider(checkProvider())
    let walletbalance
    console.log("=====walletAddress===",walletAddress)
    if (connected_wallet) {
       walletbalance = getbalance(connected_wallet)
      console.log("=====walletbalance===",walletbalance)
    }else{
      if(walletAddress.length>0){
         walletbalance = getbalance(walletAddress[0])
      }
    }

    formatChainAsNum()

  }, [walletAddress])



  //   const iswallet = useSelector((state)=>state.reducer.walletConnected)
  try {
    window.ethereum.on('accountsChanged', async () => {
      // connectWallet()
    })
    window.ethereum.on('disconnect', async () => {
      dispatch(setwalletConnected(false))
      dispatch(setWalletAddress({ accounts: [] }))
   
      // location.clear()

    });
  } catch (error) {
    if (error == "ProviderError: Provider not available. Use `.setProvider` or `.provider=` to initialize the provider.") {
      swal("warning", "Please Install Metamask", "warning")
    }
  }
  const dispatch = useDispatch()

  const [hasProvider, setHasProvider] = useState(false)
  const [balanceUser, setbalanceUser] = useState(0)
  const [iswallet, setiswallet] = useState(localStorage.getItem("is_walletConnected"))
  const [connected_wallet, setconnected_wallet] = useState(localStorage.getItem("connected_wallet"))
  const disconnect = () => {
    dispatch(setwalletConnected(false))
    dispatch(setWalletAddress({ accounts: [] }))
    setconnected_wallet()
    setiswallet()
    localStorage.clear()
    swal("Success", "Wallet disconnected successfully", "success").then(() => {
      localStorage.clear();
      window.location.reload()
    })
  }
  const connectWallet = async () => {

    let accounts = await Handleconnect()
    console.log("====accounts====", accounts);
    if (accounts == "meta_error") {
      // handleClose()
    } else {
      if (accounts) {
        dispatch(setwalletConnected(true))
        dispatch(setWalletAddress(accounts))
        handleShowConnect()
        // setShow(false)
      }
    }

  }
  const checkConnection = ()=>{
    const webb3 = new Web3(window.ethereum);
    webb3.eth.getAccounts()
    .then(async (addr) => {
        if(addr.length==0){
          dispatch(setwalletConnected(true))
          dispatch(setWalletAddress(addr))
          localStorage.clear()
        }
       
    });
  }

  const getbalance = async (walletAddress) => {
           const webb3 = new Web3(window.ethereum);
        //     let predictionContract = new webb3.eth.Contract(contractAbi, address.address);
        let toContract = new webb3.eth.Contract(tokenAbi, tokenAddress);
      
        let userBalance = await toContract.methods.balanceOf(walletAddress).call();
        
  
        // let walletconnect = await  connectWallet()
        let balance = formatBalance(userBalance.toString())
        dispatch(setwalletBalance(balance))
        console.log("====balance====",balance)
      
        setbalanceUser(balance)
    
  }
    return (
        <>
            <section className={scroll ? "header scrolled" : "header"}>
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Brand href="/"><img src={require("../assets/images/logo.png")} alt="img" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link href="#home">About Us</Nav.Link>
                                <Nav.Link href="#link">FAQs</Nav.Link>
                                <Nav.Link href="#link">Contact us</Nav.Link>
                                <div className="connect-wallet-btn" >
                  {iswallet || walletAddress.length > 0 ?
                    // <Nav.Link href="#">{formatAddress(connected_wallet ? connected_wallet : walletAddress[0])}</Nav.Link>
                    <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" >
                    {formatAddress(connected_wallet ? connected_wallet : walletAddress[0])}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    <Dropdown.Item className="disabled" >
                        <img src={require("../assets/images/usd-coin.png")} alt="img" />
                        {walletBalance?walletBalance:balanceUser}</Dropdown.Item>
                        <Dropdown.Item href={location.pathname=="/transaction-management"? "javascript:void(0)":"/transactions"}>
                            <img src={require("../assets/images/transaction.svg").default} alt="transaction"/>
                            Transactions</Dropdown.Item>
                        <Dropdown.Item href=""  onClick={disconnect}>
                            <img src={require("../assets/images/disconnect.svg").default} alt="disconnect" />
                            Disconnect</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                    :
             
                    <Nav.Link href="#" onClick={connectWallet}><Button className="connect-wallet-btn-top">Connect Wallet</Button></Nav.Link>

                  }
                 
                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </section>

        </>
    )
};
export default Header;
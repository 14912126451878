import React, { useState, useRef, useEffect } from "react";
import "./SpinWheel.css"; // Import CSS file for styling
import { Button, Modal } from "react-bootstrap";

const SpinWheel = ({
  spinTimes,
  betNumber,
  tmpArray,
  spinChance,
  canSpinNow,
  setcanSpinNow,
  spinNumber,
  setspinNumber,
  setdisableAreaLeft,
  winningAmount,
}) => {
  const [spinning, setSpinning] = useState(false);
  let currentRotation = 0; // Track current rotation of the wheel
  let finalRotation = 0; // Where the wheel should stop
  let animationFrame;
  let ctx;
  const wheelValues = [...Array(40).keys()]
    .map((i) => i)
    .sort(() => Math.random() - 0.5);
  const slices = wheelValues.length; // Adjust the number of slices as needed
  const sliceDeg = 360 / slices;

  const canvasRef = useRef(null);

  const [showCongrats, setShowCongrats] = useState(false);
  const handleCloseCongrats = () => setShowCongrats(false);
  const handleShowCongrats = () => setShowCongrats(true);
  const [showLose, setShowLose] = useState(false);

  const [drawWheelCall, setDrawWheelCall] = useState(true);

  const handleCloseLose = () => {
    console.log("handleCloseLose");
    setShowLose(false);
  };
  const handleShowLose = () => setShowLose(true);

  useEffect(() => {
    console.log("debug spinwhell useffect call");
    if (drawWheelCall && canvasRef && canvasRef.current) {
      ctx = canvasRef.current.getContext("2d");
      console.log("debug canvasRef call");
      drawWheel();
      setDrawWheelCall(false);
      // spinBoot();
    }
  }, [canvasRef, canvasRef.current]);


  const [spinChances, setSpinChances] = useState(spinChance);

  async function spinWheel() {
    // alert(betNumber)
    try {

      setSpinChances(Number(spinChances));
      // setSpinResult(tmpArray);
      startSpin(tmpArray[Number(spinChances)]);
    } catch (error) {
      // handleClosePlaceBetSteps()
      console.log("error--------->", error);
    }
  }

  async function startSpinWheel() {
    // console.log("====spinChances===", spinChance);
    // console.log("====spinNumber===", spinNumber);
    // console.log("====tmpArray===", tmpArray);
    if (spinChance == 0) {
      setSpinChances(spinChance);
    }
    if (spinNumber > 0) {
      setspinNumber(spinNumber - 1);
      setSpinChances(spinChances + 1);
      startSpin(tmpArray[Number(spinChances)]);
    } else {
      if (spinNumber > 0) {
        setspinNumber(spinNumber - 1);

        spinWheel();
      }
      if (spinNumber == 0) {
        setcanSpinNow(false);
      }
    }
  }

  const drawWheel = () => {
    console.log("debug drawwheel call");
    if (ctx) {
      console.log("debug drawwheel call ctx");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      for (let i = 0; i < slices; i++) {
        drawSlice(i, wheelValues[i]);
      }
    } else if (canvasRef.current) {
      console.log("debug drawwheel call else if");
      ctx = canvasRef.current.getContext("2d");
    }
  };

  // Draw a slice
  function drawSlice(i, label) {
    let startAngle = ((i * sliceDeg + currentRotation) * Math.PI) / 180;
    let endAngle = (((i + 1) * sliceDeg + currentRotation) * Math.PI) / 180;
    ctx.beginPath();
    ctx.fillStyle =
      label == 0 ? "#E91E63 " : i % 2 === 0 ? "#540071" : "#FBB936";
    ctx.moveTo(225, 225);
    ctx.arc(225, 225, 225, startAngle, endAngle);
    ctx.closePath();
    ctx.fill();
    ctx.save();

    ctx.translate(225, 225);
    ctx.rotate((startAngle + endAngle) / 2);
    ctx.textAlign = "right";
    ctx.fillStyle = "white";
    ctx.font = "15px sans-serif";
    ctx.fillText(label, 215, 10);
    ctx.fillText("||", 160, 10);
    ctx.fillText("*", 110, 10);
    ctx.restore();
  }

  function animateSpin(duration = 5000) {
    // let duration = 100; // Duration in milliseconds
    let startTimestamp;

    function runAnimation(timestamp) {
      if (!startTimestamp) startTimestamp = Number(timestamp);
      let deltaTime = Number(timestamp) - Number(startTimestamp);
      let progress = Number(deltaTime) / Number(duration);

      progress = progress > 1 ? 1 : progress; // Clamp progress between 0 and 1
      // Applying easing (EaseOutQuint)
      let easeProgress = 1 - Math.pow(1 - progress, 5);

      // Current angle to display
      currentRotation = ((finalRotation * easeProgress) % 360) - 90;
      // Redraw Wheel
      drawWheel();

      if (progress < 1) {
        animationFrame = requestAnimationFrame(runAnimation);
      } else {
        if (duration != 100) {
          finishSpin();
        } else {
          setSpinning(false);
        }
      }
    }

    requestAnimationFrame(runAnimation);
  }

  const startSpin = (result) => {
    setSpinning(true);
    // const result = Math.floor(Math.random() * 35) + 1;
    const stopAt = wheelValues.findIndex((value) => value === result);
    const offset = 5; // degrees of additional offset to ensure the number is in the middle of the slice
    finalRotation = 360 - stopAt * sliceDeg + 360 * 5 - offset; // Make 5 full rotations plus rotation to stop at the right number
    animateSpin();
  };

  function finishSpin() {
    let resultIndex = Math.floor((360 - (finalRotation % 360)) / sliceDeg);
    let result = wheelValues[resultIndex];
    //  setBetNumber()
    // spinBoot();
    if (betNumber == result) {
      handleShowCongrats();
      setcanSpinNow(false);
      //   if(spinNumber==1){
      setdisableAreaLeft(false);
      setSpinning(true);
      //   }
      // alert('Result: ' + result);
    } else {
      console.log("======spinNumber====", spinNumber);
      // handleShowCongrats()
      if (spinNumber == 1) {
        setdisableAreaLeft(false);
        setcanSpinNow(false);
        setSpinChances(0);
        handleShowLose();
      }
      if (spinNumber >= 1) {
        setSpinning(false);
      }

      // alert('Result: sorry sorry ...' + result);
    }
  }

  function finishSpin() {
    let resultIndex = Math.floor((360 - (finalRotation % 360)) / sliceDeg);
    let result = wheelValues[resultIndex];
    //  setBetNumber()
    // spinBoot();
    if (betNumber == result) {

      handleShowCongrats()
      setcanSpinNow(false)
      //   if(spinNumber==1){
      setdisableAreaLeft(false)
      setSpinning(true)
      //   }
      // alert('Result: ' + result);
    } else {
      console.log("======spinNumber====", spinNumber)
      // handleShowCongrats()
      if (spinNumber == 1) {
        setdisableAreaLeft(false)
        setcanSpinNow(false)
        setSpinChances(0)
        handleShowLose()
      }
      if (spinNumber >= 1) {
        setSpinning(false)
      }

      // alert('Result: sorry sorry ...' + result);
    }

  }


  return (
    <>
      <div className={canSpinNow ? 'spin-wheel-outer' : 'spin-wheel-outer disable-area'}>
        {/* <div className='spin-wheel-outcome'>
                <h3>Outcomes</h3>
                <ul>
                    <li><i><b>1 Try:</b>20</i></li>
                    <li><i><b>2 Try:</b>33</i></li>
                    <li><i><b>3 Try:</b>40  </i></li>
                </ul>
            </div> */}
        <div className="spin-wheel">
          <div id="wheel-arrow">
            <img src={require("../assets/images/spin-top-arrow.png")} alt="arrow" />
          </div>
          <div id="wheel-container">
            <canvas id="wheel" ref={canvasRef} width="450" height="450"></canvas>
          </div>
          {/* {spinNumber==0 ? <Button onClick={() =>startSpinWheel()} >Re-Try</Button> : */}
          <>
            {!canSpinNow ?
              <Button onClick={() => startSpinWheel()} disabled={canSpinNow}> {`Spin`}</Button> :
              <Button onClick={() => startSpinWheel()} disabled={spinning}> {spinning ? "Spinning..." : `Spin ${spinChances}/${Number(spinTimes) + 1}`}</Button>}
          </>
          {/* } */}
        </div>
      </div>
      <Modal show={showCongrats} onClick={handleCloseCongrats} centered className="congrats-popup congratulation-popup">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <img src={require("../assets/images/congrats.png")} alt="img" />
          <h5 className="russo-one">You Won!</h5>
          <p>Your victory has brought home the prize money i.e {winningAmount}!.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="unset" onClick={handleCloseCongrats}>
            Play Again
          </Button>

        </Modal.Footer>
      </Modal>
      <Modal show={showLose} onClick={handleCloseLose} centered className="congrats-popup congratulation-popup">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          {/* <img src={require("../assets/images/lost-icon.png")} alt="img" className="lose-picture" /> */}
          <img src={require("../assets/images/lose-icon.gif")} alt="img" className="lose-picture" />
          <h5 className="russo-one">You Lost!</h5>
          <p>Keep spinning! Better luck next time.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="unset" onClick={handleCloseLose}>
            Play Again
          </Button>

        </Modal.Footer>
      </Modal>
    </>

  );
};

export default SpinWheel;
